import { useEffect, useState } from 'react';
import { registerSW } from 'virtual:pwa-register';

interface ServiceWorkerState {
  needRefresh: boolean;
  updateServiceWorker: (() => Promise<void>) | null;
}

export function useServiceWorker() {
  const [swState, setSwState] = useState<ServiceWorkerState>({
    needRefresh: false,
    updateServiceWorker: null,
  });

  useEffect(() => {
    const updateSW = registerSW({
      onNeedRefresh() {
        setSwState({
          needRefresh: true,
          updateServiceWorker: updateSW,
        });
      },
      onOfflineReady() {
        console.log('App is ready for offline use');
      },
      immediate: true
    });

    return () => {
      // Cleanup if needed
    };
  }, []);

  const updateApp = async () => {
    if (swState.updateServiceWorker) {
      await swState.updateServiceWorker();
      window.location.reload();
    }
  };

  return {
    needRefresh: swState.needRefresh,
    updateApp
  };
}
