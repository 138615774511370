import { Check, Copy, Loader2 } from "lucide-react";
import { useEffect, useRef, useState } from "react";

export type CopyableFieldProps = {
  label?: string;
  value: string;
  isDark: boolean;
  loading?: boolean;
  showfull?: boolean;
  alignLeft?: boolean;
};

export function CopyableField({
  label,
  value,
  isDark,
  loading,
  showfull,
  alignLeft,
}: CopyableFieldProps) {
  const [copied, setCopied] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [charLimit, setCharLimit] = useState(50); // Default limit

  useEffect(() => {
    if (!containerRef.current) {
      console.log("no containerRef for", label);
      return;
    }

    let lastExpandState: boolean | null = null;
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width, target } = {
          width: entry.contentRect.width,
          target: entry.target,
        };
        
        // Calculate approximate characters that can fit in the available space
        // Assuming average character width is ~8px (adjust this value based on your font)
        // Subtract space for label (~128px) and copy button (~40px)
        const availableWidth = width - (label ? 128 : 48) - 40;
        const estimatedCharLimit = Math.floor(availableWidth / 8);
        
        // Set a reasonable minimum and maximum
        const newCharLimit = Math.max(20, Math.min(estimatedCharLimit, 200));
        setCharLimit(newCharLimit);
        
        const canExpand = width > 505;
        if (canExpand !== lastExpandState) {
          lastExpandState = canExpand;
        }
      }
    });

    resizeObserver.observe(containerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [label]);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(value);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  };

  // Use dynamic character limit instead of fixed 50
  const displayValue =
    !showfull && value.length > charLimit ? value.slice(0, charLimit).trimEnd() + "..." : value;

  return (
    <div className={`flex justify-between items-center py-1 ${label}`} ref={containerRef}>
      {label && (
        <span
          className={isDark ? "text-gray-400" : "text-gray-600"}
          style={{ minWidth: "8rem" }}
          
        >
          {label}:
        </span>
      )}
      {/* If label is null, make some space on the left instead */}
      {!label && <span className="w-12" />}

      <div className="flex items-center gap-2">
        {loading ? (
          <span
            className={`${isDark ? "text-blue-300" : "text-blue-600"} flex items-center`}
          >
            <Loader2 className="w-4 h-4 animate-spin mr-2" />
            Resolving...
          </span>
        ) : (
          <>
            <a
              title={value}
              className={`${isDark ? "text-gray-200" : "text-gray-800"} ${label}`}
              style={{
                whiteSpace: "break-spaces",
                textAlign: alignLeft ? "left" : "right",
                overflowWrap: ((label==="Name"||label===undefined )? "anywhere" : "normal"),
              }}
              
            >
              {displayValue}
            </a>
            <button
              onClick={handleCopy}
              className={`p-1 rounded transition-colors ${
                isDark ? "hover:bg-gray-700" : "hover:bg-gray-200"
              }`}
              title={copied ? "Copied!" : "Copy to clipboard"}
            >
              {copied ? (
                <Check className="w-4 h-4 text-green-400" />
              ) : (
                <Copy className="w-4 h-4 text-gray-400" />
              )}
            </button>
          </>
        )}
      </div>
    </div>
  );
}
