import React, { useRef } from "react";
import { Upload } from "lucide-react";
import { useTheme } from "../ThemeContext";
import { useDragAndDrop } from "../hooks/useDragAndDrop";
import { useFileHandler } from "../hooks/useFileHandler";
import "./DropZone.css";

export function DropZone() {
  const { isDark } = useTheme();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { handleFiles } = useFileHandler();
  const { isDragging, dragHandlers } = useDragAndDrop({
    onFilesDrop: handleFiles,
    fileFilter: (file) => file.name.toLowerCase().endsWith(".aax"),
  });

  // Handle file selection via click
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = e.target.files ? Array.from(e.target.files) : [];
    if (selectedFiles.length > 0) {
      handleFiles(selectedFiles);
      e.target.value = ""; // Reset input value
    }
  };

  const handleClick = () => fileInputRef.current?.click();

  return (
    <div
      {...dragHandlers}
      onClick={handleClick}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          handleClick();
        }
      }}
      className={`
        relative
        border-${isDragging ? '3' : '2'} border-dashed rounded-lg p-8 md:p-12 lg:p-16 mb-6 text-center
        transition-all dropzone-container
        ${isDragging ? "border-blue-500 bg-blue-500/20 grow-container" : "shrink-container"}
        ${isDark ? "border-gray-600 bg-gray-800/50" : "border-gray-300 bg-white"}
        cursor-pointer
      `}
    >
      {/* Hidden file input */}
      <input
        type="file"
        accept=".aax"
        multiple
        ref={fileInputRef}
        className="hidden"
        onChange={handleFileChange}
      />

      <div className="flex flex-col items-center gap-4 md:gap-6 lg:gap-8">
        <Upload
          className={`w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 ${
            isDark ? "text-gray-400" : "text-gray-400"
          }`}
        />
        <div
          className={`${
            isDark ? "text-gray-300" : "text-gray-600"
          } text-base md:text-lg lg:text-xl`}
        >
          <p>Drag and drop AAX files here or click to select</p>
        </div>
      </div>
    </div>
  );
}
