import React from 'react';
import { useServiceWorker } from '../hooks/useServiceWorker';

export const ServiceWorkerUpdate: React.FC = () => {
  const { needRefresh, updateApp } = useServiceWorker();

  if (!needRefresh) return null;

  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700 z-50">
      <p className="text-gray-700 dark:text-gray-300 mb-2">
        A new version is available!
      </p>
      <div className="flex justify-end space-x-2">
        <button
          onClick={updateApp}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        >
          Update
        </button>
      </div>
    </div>
  );
};
