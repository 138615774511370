import { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, MessageCircle, HelpCircle, ChevronLeft, ChevronRight, Monitor } from 'lucide-react';
import { MessageList } from './MessageList';
import { MessageForm } from './MessageForm';
import { DiscordIcon, GithubIcon } from "../assets/icons";
import { SystemInfo } from './SystemInfo';

interface HelpModalProps {
    isOpen: boolean;
    onClose: () => void;
    initialTab?: Tab;
    showMessageForm?: boolean;
}

type Tab = 'faq' | 'messages' | 'community' | 'system';

const faqItems = [
    {
        question: "Where is my audiobook going?",
        answer: "All conversions happen locally on your computer. No audio data is sent to any external servers. The converted file will be saved to your chosen output location."
    },
    {
        question: "Where can i get aax files?",
        answer: "You can download AAX files from Audible. If you have an Audible subscription, you can download audiobooks directly from the Audible website. (Eg.: https://www.audible.de/library/titles)"
    },
    {
        question: "Is this even legal?",
        answer: "The legality of converting AAX files depends on your local laws. Audible explicitly grants you the ownership of the audiobook files you purchase (like a real book). In countries like Austria, you are allowed to convert your audiobooks for personal use and even share them with friends (yay). However, in other countries, this may not be the case. I am not a lawyer, and I cannot provide legal advice. Please check your local laws before using this tool."
    },
    {
        question: "Can I convert multiple files at once (batch conversion)?",
        answer: "We are glad to announce that batch conversion is now supported! You can drag and drop multiple AAX files into the app, and they will be converted in sequence. The converted files will be saved to your chosen output location."
    },
    {
        question: "My conversion is stuck at n%",
        answer: "Then it's not going to work. I am sorry, the audiobook is probably too big. Try chrome on a desktop. The conversion process is limited by the browser's memory."
    },
    {
        question: "How do I convert to MP3?",
        answer: "Drag and drop your AAX file into the app, select MP3 as the output format(next to the 'Convert' button), and click 'Convert'. The converted file will be saved to your chosen output location."
    },
    {
        question: "Can I convert AAXC files?",
        answer: "AAXC files are currently not supported by this tool."
    },  
    {
        question: "Are chapter names preserved in the converted files?",
        answer: "Chapter markers are preserved when converting to formats that support them, like M4B."
    },
    {
        question: "Where is Expert Mode?",
        answer: "Look for the 'Expert Mode' toggle in the header of the app. ",
    }
];

export function HelpModal({ isOpen, onClose, initialTab = 'faq', showMessageForm: initialShowMessageForm = false }: HelpModalProps) {
    const [activeTab, setActiveTab] = useState<Tab>(initialTab);
    const [expandedFaq, setExpandedFaq] = useState<number | null>(0);
    const [showMessageForm, setShowMessageForm] = useState(false);
    const [showScrollButtons, setShowScrollButtons] = useState(false);
    const tabsContainerRef = useRef<HTMLDivElement>(null);
    const [initialOpened, setInitialOpened] = useState(false);

    // Update state when props change
    useEffect(() => {
        if (isOpen) {
            setInitialOpened(true);
            setActiveTab(initialTab);
            setShowMessageForm(initialTab === 'messages' ? initialShowMessageForm : false);
        }
    }, [isOpen, initialTab, initialShowMessageForm, initialOpened]);

    const handleTabChange = (tab: Tab) => {
        setActiveTab(tab);
        // Always ensure message form is hidden when switching tabs
        setShowMessageForm(false);
    };
    
    const scrollTabs = (direction: 'left' | 'right') => {
        if (!tabsContainerRef.current) return;
        
        const scrollAmount = 100; // pixels to scroll
        const currentScroll = tabsContainerRef.current.scrollLeft;
        tabsContainerRef.current.scrollTo({
            left: direction === 'left' ? currentScroll - scrollAmount : currentScroll + scrollAmount,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        const checkScroll = () => {
            if (!tabsContainerRef.current) return;
            
            const { scrollWidth, clientWidth } = tabsContainerRef.current;
            setShowScrollButtons(scrollWidth > clientWidth);
        };
        
        checkScroll();
        window.addEventListener('resize', checkScroll);
        return () => window.removeEventListener('resize', checkScroll);
    }, []);
    
    return (
        <AnimatePresence>
            {isOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center">
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed inset-0 bg-black/50 backdrop-blur-sm"
                        onClick={onClose}
                    />
                    <motion.div
                        initial={{ opacity: 0, y: 100 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 100 }}
                        className="relative z-50 w-[95%] max-w-lg rounded-lg bg-white p-4 sm:p-6 shadow-xl dark:bg-gray-800 overflow-hidden"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex items-center justify-between mb-4">
                            <h2 className="text-xl font-semibold dark:text-white">Help & Support</h2>
                            <button
                                onClick={onClose}
                                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                            >
                                <X size={24} />
                            </button>
                        </div>

                        <div className="relative mb-4">
                            {showScrollButtons && (
                                <>
                                    <button 
                                        onClick={() => scrollTabs('left')}
                                        className="absolute left-0 z-10 h-full px-1 flex items-center justify-center bg-gradient-to-r from-white to-white/0 dark:from-gray-800 dark:to-gray-800/0"
                                        aria-label="Scroll tabs left"
                                    >
                                        <ChevronLeft size={20} className="text-gray-500" />
                                    </button>
                                    <button 
                                        onClick={() => scrollTabs('right')}
                                        className="absolute right-0 z-10 h-full px-1 flex items-center justify-center bg-gradient-to-l from-white to-white/0 dark:from-gray-800 dark:to-gray-800/0"
                                        aria-label="Scroll tabs right"
                                    >
                                        <ChevronRight size={20} className="text-gray-500" />
                                    </button>
                                </>
                            )}
                            
                            <div 
                                ref={tabsContainerRef}
                                className="flex overflow-x-auto border-b border-gray-200 dark:border-gray-700 no-scrollbar"
                                style={{
                                    scrollbarWidth: 'none',  /* Firefox */
                                    msOverflowStyle: 'none',  /* IE and Edge */
                                }}
                            >
                                <button
                                    onClick={() => handleTabChange('faq')}
                                    className={`flex-shrink-0 flex items-center gap-2 px-4 py-2 font-medium whitespace-nowrap ${
                                        activeTab === 'faq'
                                            ? 'text-blue-600 border-b-2 border-blue-600'
                                            : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200'
                                    }`}
                                >
                                    <HelpCircle size={20} />
                                    FAQ
                                </button>
                                <button
                                    onClick={() => handleTabChange('messages')}
                                    className={`flex-shrink-0 flex items-center gap-2 px-4 py-2 font-medium whitespace-nowrap ${
                                        activeTab === 'messages'
                                            ? 'text-blue-600 border-b-2 border-blue-600'
                                            : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200'
                                    }`}
                                >
                                    <MessageCircle size={20} />
                                    Messages
                                </button>
                                <button
                                    onClick={() => handleTabChange('community')}
                                    className={`flex-shrink-0 flex items-center gap-2 px-4 py-2 font-medium whitespace-nowrap ${
                                        activeTab === 'community'
                                            ? 'text-blue-600 border-b-2 border-blue-600'
                                            : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200'
                                    }`}
                                >
                                    {DiscordIcon}
                                    Community
                                </button>
                                <button
                                    onClick={() => handleTabChange('system')}
                                    className={`flex-shrink-0 flex items-center gap-2 px-4 py-2 font-medium whitespace-nowrap ${
                                        activeTab === 'system'
                                            ? 'text-blue-600 border-b-2 border-blue-600'
                                            : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200'
                                    }`}
                                >
                                    <Monitor size={20} />
                                    System Info
                                </button>
                            </div>
                        </div>

                        <AnimatePresence mode="wait">
                            {activeTab === 'faq' && (
                                <motion.div
                                    key="faq"
                                    initial={{ opacity: 0, x: 20 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: -20 }}
                                    className="space-y-4 max-h-[60vh] overflow-y-auto pr-2 custom-scrollbar no-scrollbar"
                                    style={{
                                        scrollbarWidth: 'none',  /* Firefox */
                                        msOverflowStyle: 'none',  /* IE and Edge */
                                    }}
                                >
                                    {faqItems.map((item, index) => (
                                        <div key={index} className="border-b dark:border-gray-700">
                                            <button
                                                className="w-full py-4 flex justify-between items-center text-left hover:text-blue-600 dark:hover:text-blue-400"
                                                onClick={() => setExpandedFaq(expandedFaq === index ? null : index)}
                                            >
                                                <span className="font-medium">{item.question}</span>
                                                <motion.span
                                                    animate={{ rotate: expandedFaq === index ? 180 : 0 }}
                                                    className="text-2xl"
                                                >
                                                    ▼
                                                </motion.span>
                                            </button>
                                            <AnimatePresence>
                                                {expandedFaq === index && (
                                                    <motion.div
                                                        initial={{ height: 0, opacity: 0 }}
                                                        animate={{ height: "auto", opacity: 1 }}
                                                        exit={{ height: 0, opacity: 0 }}
                                                        className="overflow-hidden"
                                                    >
                                                        <p className="pb-4 text-gray-600 dark:text-gray-400">
                                                            {item.answer}
                                                        </p>
                                                    </motion.div>
                                                )}
                                            </AnimatePresence>
                                        </div>
                                    ))}
                                </motion.div>
                            )}

                            {activeTab === 'messages' && (
                                <motion.div
                                    key="messages"
                                    initial={{ opacity: 0, x: 20 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: -20 }}
                                >
                                    <div className="space-y-4">
                                        <div className="flex justify-end mb-4">
                                            {!showMessageForm && (
                                                <button
                                                    onClick={() => setShowMessageForm(true)}
                                                    className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
                                                >
                                                    <MessageCircle size={16} />
                                                    Leave a Message
                                                </button>
                                            )}
                                        </div>
                                        <AnimatePresence mode="wait">
                                            {showMessageForm ? (
                                                <motion.div
                                                    key="form"
                                                    initial={{ opacity: 0, x: 20 }}
                                                    animate={{ opacity: 1, x: 0 }}
                                                    exit={{ opacity: 0, x: -20 }}
                                                >
                                                    <MessageForm 
                                                        onSuccess={() => setShowMessageForm(false)}
                                                        onCancel={() => setShowMessageForm(false)}
                                                    />
                                                </motion.div>
                                            ) : (
                                                <motion.div
                                                    key="list"
                                                    initial={{ opacity: 0, x: -20 }}
                                                    animate={{ opacity: 1, x: 0 }}
                                                    exit={{ opacity: 0, x: 20 }}
                                                >
                                                    <MessageList />
                                                </motion.div>
                                            )}
                                        </AnimatePresence>
                                    </div>
                                </motion.div>
                            )}

                            {activeTab === 'community' && (
                                <motion.div
                                    key="community"
                                    initial={{ opacity: 0, x: 20 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: -20 }}
                                    className="space-y-6"
                                >
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <div className="flex flex-col items-center space-y-4 p-4 border border-gray-200 rounded-lg dark:border-gray-700">
                                            <a
                                                href="https://discord.com/invite/P9evWVw8DW"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="inline-flex items-center gap-2 px-6 py-3 bg-[#5865F2] text-white rounded-lg hover:bg-[#4752C4] transition-colors w-full justify-center"
                                            >
                                                {DiscordIcon}
                                                <span className="font-medium">Join Discord</span>
                                            </a>
                                            <p className="text-gray-600 dark:text-gray-400">
                                                Join our Discord community for support, discussions, and updates!
                                            </p>
                                        </div>
                                        
                                        <div className="flex flex-col items-center space-y-4 p-4 border border-gray-200 rounded-lg dark:border-gray-700">
                                            <a
                                                href="https://github.com/audible-tools/audible-tools.github.io/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="inline-flex items-center gap-2 px-6 py-3 bg-gray-900 text-white rounded-lg hover:bg-gray-800 dark:bg-gray-700 dark:hover:bg-gray-900 transition-colors w-full justify-center"
                                            >
                                                {GithubIcon}
                                                <span className="font-medium">View GitHub</span>
                                            </a>
                                            <p className="text-gray-600 dark:text-gray-400">
                                                Check out our GitHub repository for source code, contributions, and creating feature requests!
                                            </p>
                                        </div>
                                    </div>
                                </motion.div>
                            )}

                            {activeTab === 'system' && (
                                <motion.div
                                    key="system"
                                    initial={{ opacity: 0, x: 20 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    exit={{ opacity: 0, x: -20 }}
                                >
                                    <SystemInfo />
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    );
}

// Add this to the file, typically at the top or bottom of the file
// Add global styles for scrollbar hiding
const styles = `
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
    
    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
`;

// Add the style to the document head
if (typeof document !== 'undefined') {
    const styleElement = document.createElement('style');
    styleElement.textContent = styles;
    document.head.appendChild(styleElement);
}
