import React, { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Settings2, Sun, Moon, HelpCircle, HandCoinsIcon } from "lucide-react";
import { useTheme } from "../ThemeContext";
import { DonationModal } from "./DonationModal";

type HeaderProps = {
  expertMode: boolean;
  setExpertMode: (value: boolean) => void;
  onMessageClick: (
    initialTab: "faq" | "messages" | "community" | null,
    showMessageForm: boolean
  ) => void;
};

// Define fixed breakpoints for better predictability
const BREAKPOINTS = {
  sm: 480, // Donate text appears
  md: 600, // Expert Mode text appears
  lg: 1024, // Full title appears
};

type ExpandedStates = {
  donate: boolean;
  expertMode: boolean;
  title: boolean;
};

export function Header({ expertMode, setExpertMode, onMessageClick }: HeaderProps) {
  const { isDark, toggleTheme } = useTheme();
  const [expandedStates, setExpandedStates] = useState<ExpandedStates>({
    donate: false,
    expertMode: false,
    title: false,
  });
  const [isDonationModalOpen, setIsDonationModalOpen] = useState(false);
  const setContainerWidth = useState<number>(0)[1];
  const containerRef = useRef<HTMLDivElement>(null);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  // Update expanded states based on container width
  useEffect(() => {
    // Define update function for clarity
    const updateExpandedStates = (width: number) => {
      setExpandedStates({
        donate: width >= BREAKPOINTS.sm,
        expertMode: width >= BREAKPOINTS.md,
        title: width >= BREAKPOINTS.lg,
      });
    };

    // Initial update
    if (containerRef.current) {
      const width = containerRef.current.clientWidth;
      setContainerWidth(width);
      updateExpandedStates(width);
    }

    // Setup resize observer (more reliable than window resize)
    resizeObserverRef.current = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const width = entry.contentRect.width;
        setContainerWidth(width);
        updateExpandedStates(width);
      }
    });

    if (containerRef.current) {
      resizeObserverRef.current.observe(containerRef.current);
    }

    // Cleanup
    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, []);

  return (
    <>
      <div
        className="fixed top-0 left-0 right-0 z-50"
        style={{ backdropFilter: "blur(10px)" }}
      >
        <div
          className={`w-full ${isDark ? "bg-gray-900/80" : "bg-white/80"} shadow-md`}
          ref={containerRef}
        >
          <div className="max-w-7xl mx-auto px-3 sm:px-4 lg:px-6">
            <div className="flex justify-between items-center h-14 md:h-16 lg:h-20">
              {/* Logo/Title */}
              <h1
                className={`text-lg md:text-2xl lg:text-3xl font-bold ${
                  isDark ? "text-white" : "text-gray-800"
                }`}
                style={{ textWrap: "nowrap" }}
              >
                <span className="text-2xl md:text-3xl lg:text-4xl font-bold">
                  {expandedStates.title ? "AAX to M4B Converter" : "AAX Converter"}
                </span>
              </h1>

              {/* Navigation */}
              <div
                className={`flex items-center divide-x ${
                  isDark ? "divide-gray-700" : "divide-gray-200"
                }`}
              >
                {/* Donate Button */}
                <div
                  onClick={() => setIsDonationModalOpen(true)}
                  className={`flex items-center gap-2 px-4 py-2 md:px-5 md:py-2.5 lg:px-6 lg:py-3 cursor-pointer transition-colors ${
                    isDark ? "hover:text-blue-400" : "hover:text-blue-600"
                  }`}
                  role="button"
                  title="Support the Project"
                >
                  <HandCoinsIcon className="w-6 h-6 md:w-7 md:h-7 lg:w-8 lg:h-8" />
                  <AnimatePresence>
                    {expandedStates.donate && (
                      <motion.span
                        className="text-base md:text-lg lg:text-xl font-medium"
                        initial={{ opacity: 0, width: 0 }}
                        animate={{ opacity: 1, width: "auto", marginLeft: "8px" }}
                        exit={{ opacity: 0, width: 0, marginLeft: "0px" }}
                        transition={{ duration: 0.3 }}
                      >
                        Donate
                      </motion.span>
                    )}
                  </AnimatePresence>
                </div>

                {/* Expert Mode Toggle Button */}
                <div
                  onClick={() => setExpertMode(!expertMode)}
                  className={`flex items-center gap-2 px-4 py-2 md:px-5 md:py-2.5 lg:px-6 lg:py-3 cursor-pointer transition-colors ${
                    expertMode
                      ? isDark
                        ? "text-blue-400"
                        : "text-blue-600"
                      : isDark
                      ? "hover:text-blue-400"
                      : "hover:text-blue-600"
                  }`}
                  role="button"
                  title="Toggle Expert Mode"
                >
                  <Settings2 className="w-6 h-6 md:w-7 md:h-7 lg:w-8 lg:h-8" />
                  <AnimatePresence>
                    {expandedStates.expertMode && (
                      <motion.span
                        className="text-base md:text-lg lg:text-xl font-medium"
                        initial={{ opacity: 0, width: 0 }}
                        animate={{ opacity: 1, width: "auto", marginLeft: "8px" }}
                        exit={{ opacity: 0, width: 0, marginLeft: "0px" }}
                        transition={{ duration: 0.3 }}
                        style={{ textWrap: "nowrap" }}
                      >
                        Expert Mode
                      </motion.span>
                    )}
                  </AnimatePresence>
                </div>

                {/* Message Button (Always Collapsed) */}
                <div
                  onClick={() => onMessageClick(null, false)}
                  className={`flex items-center px-4 py-2 md:px-5 md:py-2.5 lg:px-6 lg:py-3 cursor-pointer transition-colors ${
                    isDark ? "hover:text-blue-400" : "hover:text-blue-600"
                  }`}
                  role="button"
                  title="Help & Support"
                >
                  <HelpCircle className="w-6 h-6 md:w-7 md:h-7 lg:w-8 lg:h-8" />
                </div>

                {/* Theme Toggle Button */}
                <div className="flex items-center">
                  <div
                    onClick={toggleTheme}
                    className={`relative px-4 py-2 md:px-5 md:py-2.5 lg:px-6 lg:py-3 cursor-pointer transition-colors ${
                      isDark ? "hover:text-blue-400" : "hover:text-blue-600"
                    }`}
                    role="button"
                    title="Toggle Theme"
                  >
                    <motion.div
                      className="absolute inset-0 z-0"
                      animate={{
                        background: isDark
                          ? "radial-gradient(circle at center, rgba(59, 130, 246, 0.4) 0%, rgba(30, 58, 138, 0) 70%)"
                          : "radial-gradient(circle at center, rgba(253, 224, 71, 0.3) 0%, rgba(250, 204, 21, 0) 70%)",
                      }}
                      transition={{ duration: 0.5 }}
                    />
                    <motion.div
                      className="relative z-10 flex items-center justify-center w-6 h-6 md:w-7 md:h-7 lg:w-8 lg:h-8"
                      animate={{ rotate: 360 }}
                      transition={{
                        duration: 0.7,
                        ease: "easeInOut",
                      }}
                      key={isDark ? "dark" : "light"}
                    >
                      {isDark ? (
                        <motion.div
                          initial={{ opacity: 0, scale: 0.5 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 0.5 }}
                          transition={{ duration: 0.2 }}
                        >
                          <Moon className="w-6 h-6 md:w-7 md:h-7 lg:w-8 lg:h-8" />
                        </motion.div>
                      ) : (
                        <motion.div
                          initial={{ opacity: 0, scale: 0.5 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 0.5 }}
                          transition={{ duration: 0.2 }}
                        >
                          <Sun className="w-6 h-6 md:w-7 md:h-7 lg:w-8 lg:h-8" />
                        </motion.div>
                      )}
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DonationModal
        isOpen={isDonationModalOpen}
        onClose={() => setIsDonationModalOpen(false)}
        onMessageClick={() => onMessageClick("messages", true)}
      />
    </>
  );
}
