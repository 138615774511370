import { openDB, DBSchema, IDBPDatabase } from 'idb';

interface ActivationEntry {
  checksum: string;
  activationBytes: string;
  timestamp: number;
  expiresAt: number;
}

interface ActivationDBSchema extends DBSchema {
  activationBytes: {
    key: string;
    value: ActivationEntry;
    indexes: { 'by-expiry': number };
  };
}

const DB_NAME = 'activation-cache';
const STORE_NAME = 'activationBytes';
const CACHE_DURATION = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

export class ActivationCache {
  private db: IDBPDatabase<ActivationDBSchema> | null = null;

  async init() {
    if (this.db) return;

    this.db = await openDB<ActivationDBSchema>(DB_NAME, 1, {
      upgrade(db) {
        const store = db.createObjectStore(STORE_NAME, { keyPath: 'checksum' });
        store.createIndex('by-expiry', 'expiresAt');
      },
    });

    // Clean up expired entries
    await this.cleanup();
  }

  async get(checksum: string): Promise<string | null> {
    await this.init();
    if (!this.db) return null;

    const entry = await this.db.get(STORE_NAME, checksum);
    if (!entry) return null;

    // Check if entry is expired
    if (Date.now() > entry.expiresAt) {
      await this.delete(checksum);
      return null;
    }

    return entry.activationBytes;
  }

  async set(checksum: string, activationBytes: string): Promise<void> {
    await this.init();
    if (!this.db) return;

    const entry: ActivationEntry = {
      checksum,
      activationBytes,
      timestamp: Date.now(),
      expiresAt: Date.now() + CACHE_DURATION,
    };

    await this.db.put(STORE_NAME, entry);
  }

  async delete(checksum: string): Promise<void> {
    await this.init();
    if (!this.db) return;

    await this.db.delete(STORE_NAME, checksum);
  }

  async cleanup(): Promise<void> {
    await this.init();
    if (!this.db) return;

    const tx = this.db.transaction(STORE_NAME, 'readwrite');
    const index = tx.store.index('by-expiry');
    let cursor = await index.openCursor();

    const now = Date.now();
    while (cursor) {
      if (cursor.value.expiresAt <= now) {
        await cursor.delete();
      }
      cursor = await cursor.continue();
    }

    await tx.done;
  }
}

export const activationCache = new ActivationCache();
