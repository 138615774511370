export const Keys = {
  TARGET_FORMAT: 'audible-tools-target-format',
  SUPPRESS_SIZE_WARNING: 'suppress-size-warning'
} as const;

export function savePreference(key: keyof typeof Keys, value: string): void {
  localStorage.setItem(Keys[key], value);
}

export function loadPreference<T extends string>(
  key: keyof typeof Keys, 
  defaultValue: T, 
  validator?: (value: string) => boolean
): T {
  const value = localStorage.getItem(Keys[key]);
  if (value && (!validator || validator(value))) {
    return value as T;
  }
  return defaultValue;
}

// Session storage functions for temporary preferences
export function saveSessionPreference(key: keyof typeof Keys, value: string): void {
  sessionStorage.setItem(Keys[key], value);
}

export function loadSessionPreference(key: keyof typeof Keys, defaultValue: string): string {
  const value = sessionStorage.getItem(Keys[key]);
  return value ?? defaultValue;
}
