// import mediainfo
import { mediaInfoFactory, Track } from "mediainfo.js";
import { MappedTrackDetails } from "../models/EnrichedFile";

export const readMediaInfo = async (file: File) : Promise<MappedTrackDetails> => {
  const mediaInfo = await mediaInfoFactory({
    coverData: true
  });
  if (!mediaInfo) return { toList: () => [] };

  const readChunk = (chunkSize: number, offset: number) => {
    return new Promise<Uint8Array>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(new Uint8Array(reader.result as ArrayBuffer));
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
    });
  };

  const rawInfo = await mediaInfo.analyzeData(file.size, readChunk);
  
  console.log("MediaInfo:", rawInfo);
  const trackInfo = rawInfo.media?.track[0];
  if (!trackInfo) return { toList: () => [] };

  const trackDetails = mapTrackDetails(trackInfo);
  const toList = (filterData = true) => {
    const entries = Object.entries(trackDetails);
    if (!filterData) return entries;

    const set = new Set();
    return entries.filter(([key, value]) => {
      if (set.has(value)) return false;
      set.add(value);
      return true;
    });
  };

  return {
    ...trackDetails,
    toList,
  };
};

const durationToHumanReadable = (duration: number) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);

  return `${hours}:${minutes}:${seconds}`;
};

const mapTrackDetails = (mediaInfo: Track) => {
  if (!mediaInfo) return {};

  const mappedTrack = {
    Title: mediaInfo.Title,
    Narrator: mediaInfo.extra?.nrt,
    Publisher: mediaInfo.extra?.pub,
  };

  const mappedTrackDetails = {
    Format: mediaInfo.Format,
    CodecID: mediaInfo.CodecID,
  };


  if (mediaInfo["@type"] === "General") {
    return {
      ...mappedTrack,
      Description: mediaInfo.Title_More || mediaInfo.Description || mediaInfo.Track_More,
      Album: mediaInfo.Album,
      Performer: mediaInfo.Performer,
      Duration: durationToHumanReadable(mediaInfo.Duration),
      Genre: mediaInfo.Genre,
      Recorded_Date: mediaInfo.Recorded_Date,
      Cover_Data: mediaInfo.Cover_Data,
      Copyright: mediaInfo.Copyright,
      Encoded_Date: mediaInfo.Encoded_Date,
      OverallBitRate: mediaInfo.OverallBitRate,
      ...mappedTrackDetails,
    };
  }

  return {
    ...mappedTrack,
    ...mappedTrackDetails,
  };
};
