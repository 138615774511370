import React, { useState, useEffect } from "react";
import { Terminal } from "lucide-react";
import { useTheme } from "../ThemeContext";
import { resolveActivationBytes } from "../utils/activationResolver";
import { EnrichedFile } from "../models/EnrichedFile";
import { CopyableField } from "./CopyableField";

type ExpertModeProps = {
  file: EnrichedFile | null;
  checksum: string;
  setChecksum: (checksum: string) => void;
  activationBytes: string;
  setActivationBytes: (activationBytes: string) => void;
};

function autoDetectOsType(): "windows" | "linux" | "mac" {
  const platform = window.navigator.platform.toLowerCase();
  if (platform.includes("win")) return "windows";
  if (platform.includes("linux")) return "linux";
  return "mac";
}

function SectionHeader({ title }: { title: string }) {
  const { isDark } = useTheme();
  return (
    <div
      className={`flex items-center gap-2 mb-3 border-b pb-2 ${
        isDark ? "text-gray-400 border-gray-700" : "text-gray-700 border-gray-500"
      }`}
      style={{}}
    >
      <Terminal className="w-4 h-4" />
      <span>{title}</span>
    </div>
  );
}

/*
  Section:
  <Section title="File">
    <CopyableField label="Name" value="file.aax" />
    <CopyableField label="Size" value="10 MB" />
    <CopyableField label="Type" value="audio/aax" />
    <CopyableField label="Last Modified" value="2021-09-01 12:00:00" />
  </Section>
*/
function Section({ title, children }: { title: string; children?: React.ReactNode }) {
  return (
    <div style={{ marginBottom: "1.1rem" }}>
      <SectionHeader title={title} />
      {children}
    </div>
  );
}

export function ExpertMode({
  file: inputFile,
  checksum,
  setChecksum,
  activationBytes,
  setActivationBytes,
}: ExpertModeProps) {
  const defaultOsType = autoDetectOsType();
  console.log("defaultOsType: ", defaultOsType);

  const { isDark = false } = useTheme();
  const [isResolving, setIsResolving] = useState(false);
  const [outputFormat, setOutputFormat] = useState("m4b");
  const [osType, setOsType] = useState<"windows" | "linux" | "mac">(defaultOsType);

  // Update URL when checksum changes
  useEffect(() => {
    if (checksum && checksum.length === 40) {
      window.history.pushState({}, '', `/${checksum}`);
    }
  }, [checksum]);

  // Read checksum from URL on mount and resolve activation bytes
  useEffect(() => {
    const path = window.location.pathname;
    const urlChecksum = path.substring(1); // Remove leading slash

    if (urlChecksum && urlChecksum.length === 40) {
      setChecksum(urlChecksum);
      // Auto-resolve activation bytes
      const resolveBytes = async () => {
        setIsResolving(true);
        try {
          const resolvedBytes = await resolveActivationBytes(urlChecksum);
          setActivationBytes(resolvedBytes);
        } catch (error) {
          console.error("Failed to resolve activation bytes:", error);
        } finally {
          setIsResolving(false);
        }
      };
      resolveBytes();
    }
  }, []); // Run only on mount

  activationBytes = inputFile?.ActivationBytes || activationBytes || "";

  const getFileDetails = () => {
    if (!inputFile) return {};
    const file = inputFile.originalFile;
    return {
      Name: file.name,
      Size: (file.size / (1024 * 1024)).toFixed(2) + " MB",
      Type: file.type || "audio/aax",
      LastModified: new Date(file.lastModified).toLocaleString(),
    };
  };

  const getFfmpegCommand = () => {
    if (!inputFile) return "";
    const file = inputFile.originalFile;

    const isWindows = osType === "windows";
    const ffmpegExecutable = isWindows ? "ffmpeg.exe" : "ffmpeg";
    const quote = isWindows ? '"' : "'";
    const pathPrefix = isWindows ? ".\\" : "./";

    let codec;
    let outputExtension;

    switch (outputFormat) {
      case "m4b":
      case "m4a":
        codec = "-codec copy";
        outputExtension = outputFormat;
        break;
      case "flac":
        codec = "-codec:a flac";
        outputExtension = "flac";
        break;
      case "mp3":
        codec = "-codec:a libmp3lame";
        outputExtension = "mp3";
        break;
      default:
        codec = "-codec copy";
        outputExtension = "m4b";
    }

    const inputPath = `${pathPrefix}${file.name}`;
    const outputName = file.name.replace(/\.aax$/, `.${outputExtension}`);
    const outputPath = `${pathPrefix}${outputName}`;

    return `${ffmpegExecutable} -y -activation_bytes ${
      activationBytes || "XXXXX"
    } -i ${quote}${inputPath}${quote} ${codec} ${quote}${outputPath}${quote}`;
  };

  const infoList =
    inputFile?.info &&
    inputFile.info.toList &&
    inputFile.info.toList().filter(([key]) => key !== "Cover_Data");

  const renderFileDetails = (): React.ReactNode => {
    return (
      <>
        {/* <SectionHeader title="File" />
        {Object.entries(getFileDetails()).map(([key, value]) => (
          <CopyableField key={key} label={key} value={String(value)} isDark={isDark} />
        ))}
        <CopyableField
          label="Activation bytes"
          value={activationBytes || "XXXXX"}
          isDark={isDark}
          loading={isResolving}
        /> */}

        <Section title="File">
          {Object.entries(getFileDetails()).map(([key, value]) => (
            <CopyableField key={key} label={key} value={String(value)} isDark={isDark} />
          ))}
          <CopyableField
            label="Activation bytes"
            value={activationBytes || "XXXXX"}
            isDark={isDark}
            loading={isResolving}
          />
          <CopyableField
            label="Checksum"
            value={checksum || inputFile?.Checksum || "XXXXX"}
            isDark={isDark}
            loading={isResolving}
          />
        </Section>

        {/* <SectionHeader title="Track Infos" /> */}
        <Section title="Track Infos">
          {infoList &&
            infoList.map(([key, value]) => (
              <CopyableField
                key={key}
                label={key}
                value={String(value)}
                isDark={isDark}
              />
            ))}
        </Section>

        <Section title="FFmpeg Command">
          <div>
            <div className="space-y-4">
              <div className="grid sm:grid-cols-2 gap-2 sm:justify-end justify-center">
                {/* Output Format */}
                <div>
                  <label className="text-gray-400 block mb-2">Output Format</label>
                  <div className="flex space-x-2 justify-between sm:justify-start">
                    {["m4b", "flac", "mp3"].map((format) => (
                      <button
                        key={format}
                        onClick={() => setOutputFormat(format)}
                        className={`px-4 py-2 rounded ${
                          outputFormat === format
                            ? "bg-blue-500 text-white"
                            : isDark
                            ? "bg-gray-700 text-gray-200"
                            : "bg-gray-200 text-gray-800"
                        }`}
                      >
                        {format.toUpperCase()}
                      </button>
                    ))}
                  </div>
                </div>
                {/* Operating System */}
                <div>
                  <label className="text-gray-400 block mb-2 flex sm:justify-end ">Operating System</label>
                  <div className="flex sm:justify-end justify-start space-x-2 justify-between sm:justify-right">
                    {["windows", "linux", "mac"].map((os) => (
                      <button
                        key={os}
                        onClick={() => setOsType(os as "windows" | "linux" | "mac")}
                        className={`px-4 py-2 rounded ${
                          osType === os
                            ? "bg-blue-500 text-white"
                            : isDark
                            ? "bg-gray-700 text-gray-200"
                            : "bg-gray-200 text-gray-800"
                        }`}
                      >
                        {os.charAt(0).toUpperCase() + os.slice(1)}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
              <div className="space-y-2">
                <p className="text-gray-400">FFmpeg Command:</p>
                <div
                  className={`p-2 rounded-lg ${
                    isDark ? "bg-gray-700 text-gray-200" : "bg-gray-200 text-gray-800"
                  }`}
                >
                  <CopyableField value={getFfmpegCommand()} isDark={isDark} showfull alignLeft />
                </div>
              </div>
            </div>
          </div>
        </Section>
      </>
    );
  };

  const renderChecksumInput = (): React.ReactNode => {
    const handleResolveActivationBytes = async () => {
      setIsResolving(true);
      try {
        const resolvedBytes = await resolveActivationBytes(checksum);
        setActivationBytes(resolvedBytes);
      } catch (error) {
        console.error("Failed to resolve activation bytes:", error);
      } finally {
        setIsResolving(false);
      }
    };

    return (
      <div className="space-y-4">
        <label className="text-gray-400">Checksum:</label>
        <div className="flex items-center gap-2">
          <input
            type="text"
            value={checksum}
            onChange={(e) => setChecksum(e.target.value)}
            className={`p-2 rounded flex-grow ${
              isDark ? "bg-gray-700 text-gray-200" : "bg-gray-200 text-gray-800"
            }`}
          />
          <button
            onClick={handleResolveActivationBytes}
            className={`p-2 rounded bg-blue-500 text-white ${
              isResolving ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isResolving}
          >
            {isResolving ? "Resolving..." : "Get Activation Bytes"}
          </button>
        </div>
        {activationBytes && (
          <div className="flex flex-col gap-2">
            <label className="text-gray-400">Activation Bytes:</label>
            <input
              type="text"
              value={activationBytes}
              readOnly
              className={`p-2 rounded ${
                isDark ? "bg-gray-700 text-gray-200" : "bg-gray-200 text-gray-800"
              }`}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={`rounded-lg p-4 mb-6 font-mono text-sm ${
        isDark ? "bg-gray-800 text-gray-200" : "bg-gray-100 text-gray-900"
      }`}
    >
      {inputFile ? renderFileDetails() : renderChecksumInput()}
    </div>
  );
}
