import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useTheme } from '../ThemeContext';

interface NavigationControlsProps {
  onPrevious: (e: React.MouseEvent) => void;
  onNext: (e: React.MouseEvent) => void;
  disabled: boolean;
  showControls: boolean;
}

export function NavigationControls({ 
  onPrevious, 
  onNext, 
  disabled, 
  showControls 
}: NavigationControlsProps) {
  const { isDark } = useTheme();

  if (!showControls) return null;

  const buttonClasses = `absolute top-1/2 -translate-y-1/2 z-10 p-2 rounded-full
    ${isDark ? 'bg-gray-800 text-gray-300' : 'bg-gray-100 text-gray-700'}
    ${disabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-500 hover:text-white'}
    transition-all duration-200`;

  return (
    <>
      <button
        onClick={onPrevious}
        disabled={disabled}
        className={`${buttonClasses} left-0 -translate-x-1/4`}
        aria-label="Previous"
      >
        <ChevronLeft className="w-6 h-6" />
      </button>

      <button
        onClick={onNext}
        disabled={disabled}
        className={`${buttonClasses} right-0 translate-x-1/4`}
        aria-label="Next"
      >
        <ChevronRight className="w-6 h-6" />
      </button>
    </>
  );
}
