import { motion, AnimatePresence } from 'framer-motion';
import { AlertTriangle, X } from 'lucide-react';
import { useState } from 'react';

interface WarningModalProps {
    isOpen: boolean;
    onClose: () => void;
    onProceed: (suppressWarnings?: boolean) => void;
    filename: string;
    count?: number;
}

export function WarningModal({ isOpen, onClose, onProceed, filename, count }: WarningModalProps) {
    const [suppressWarnings, setSuppressWarnings] = useState(false);
    const message = count && count > 1 
        ? `You have selected ${count} files larger than 900MB`
        : `The file ${filename} is larger than 900MB`;

    const warning = count && count > 1
        ? "These files may take a long time to process and could potentially fail due to browser memory limitations."
        : "Due to browser memory limitations, the conversion process may fail. Consider splitting the file into smaller segments before converting.";

    // Ensure z-index is always high enough to be on top of everything
    return (
        <AnimatePresence>
            {isOpen && (
                <div className="fixed inset-0 z-[100] flex items-center justify-center">
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="fixed inset-0 bg-black/50 backdrop-blur-sm"
                            onClick={onClose}
                            role="presentation"
                            aria-hidden="true"
                        />
                    <motion.div
                        initial={{ opacity: 0, y: 100 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 100 }}
                        className="relative z-50 w-[95%] max-w-lg rounded-lg bg-white p-6 shadow-xl dark:bg-gray-800"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex items-start justify-between mb-4" role="dialog" aria-labelledby="warning-title">
                            <div className="flex items-center gap-2">
                                <AlertTriangle className="w-6 h-6 text-yellow-500" aria-hidden="true" />
                                <h2 id="warning-title" className="text-xl font-semibold dark:text-white">File Size Warning</h2>
                            </div>
                            <button
                                onClick={onClose}
                                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                            >
                                <X size={24} />
                            </button>
                        </div>

                        <div className="mb-6 space-y-4">
                            <p className="text-gray-700 dark:text-gray-300">
                                {message}
                            </p>
                            <p className="text-gray-700 dark:text-gray-300">
                                {warning}
                            </p>
                        </div>

                        <div className="flex items-center justify-between flex-wrap gap-3">
                            <label className="flex items-center gap-2 text-sm text-gray-600 dark:text-gray-400">
                                <input
                                    type="checkbox"
                                    checked={suppressWarnings}
                                    onChange={(e) => setSuppressWarnings(e.target.checked)}
                                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                />
                                Don't show this warning again
                            </label>

                            <div className="flex gap-3">
                                <button
                                    onClick={onClose}
                                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={() => {
                                        onProceed(suppressWarnings);
                                        onClose();
                                    }}
                                    className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700"
                                >
                                    Proceed Anyway
                                </button>
                            </div>
                        </div>
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    );
}
