import { useState, useEffect } from 'react';
import { Turnstile } from '@marsidev/react-turnstile';
import { sendMessage } from '../utils/supabase';
import { analytics } from '../utils/analytics';
import { ChevronLeft } from 'lucide-react';

interface MessageFormProps {
    onSuccess?: () => void;
    onCancel?: () => void;
    className?: string;
    replyTo?: {
        id: string;
        message: string;
        name: string | null;
    };
}

export function MessageForm({ onSuccess, onCancel, className = '', replyTo }: MessageFormProps) {
    useEffect(() => {
        const handleEscape = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                onCancel?.();
            }
        };
        
        window.addEventListener('keydown', handleEscape);
        return () => window.removeEventListener('keydown', handleEscape);
    }, [onCancel]);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [captchaToken, setCaptchaToken] = useState<string>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState('');

    const resetForm = () => {
        setName('');
        setEmail('');
        setMessage('');
        setError('');
        setCaptchaToken(undefined);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!message.trim()) return;

        setIsSubmitting(true);
        setError('');

        try {
            if (!captchaToken) {
                setError('Please complete the CAPTCHA');
                return;
            }

            await sendMessage({ 
                name, 
                email, 
                message, 
                captchaToken,
                parent_id: replyTo?.id 
            });

            analytics.trackEvent('MessageSent', { hasName: !!name, hasEmail: !!email });
            resetForm();
            onSuccess?.();
        } catch (err) {
            setError('Failed to send message. Please try again.');
            analytics.trackEvent('MessageError', { error: err instanceof Error ? err.message : 'Unknown error' });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className={`space-y-4 ${className}`}>
            <div>
                <div className="flex justify-between items-center mb-4">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="flex items-center gap-2 text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-200"
                        title="Press ESC to go back"
                    >
                        <ChevronLeft size={20} />
                        Back to Messages
                    </button>
                </div>
                
                {replyTo && (
                    <div className="mb-4 p-3 bg-gray-50 dark:bg-gray-700 rounded-lg">
                        <div className="text-sm text-gray-500 dark:text-gray-400 mb-1">
                            Replying to {replyTo.name || 'Anonymous'}
                        </div>
                        <div className="text-gray-700 dark:text-gray-300 text-sm line-clamp-2">
                            {replyTo.message}
                        </div>
                    </div>
                )}
            </div>
            <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Name (optional)
                </label>
                <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                    placeholder="Your name"
                />
            </div>

            <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Email (optional)
                </label>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                    placeholder="your@email.com"
                />
            </div>

            <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Message
                </label>
                <textarea
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows={4}
                    required
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                    placeholder="Type your message here..."
                />
            </div>

            <Turnstile
                siteKey="0x4AAAAAABA1D5B2CHdZk2Ir"
                onSuccess={setCaptchaToken}
                className="flex justify-center"
            />

            {error && <p className="text-sm text-red-600 dark:text-red-400">{error}</p>}

            <button
                type="submit"
                disabled={isSubmitting || !message.trim() || !captchaToken}
                className="w-full rounded-md bg-blue-600 px-4 py-2 font-medium text-white hover:bg-blue-700 disabled:opacity-50"
            >
                {isSubmitting ? 'Sending...' : 'Send Message'}
            </button>
        </form>
    );
}
