import { useState, useCallback, DragEvent, useRef } from 'react';

interface DragAndDropOptions {
  onFilesDrop: (files: File[]) => void;
  fileFilter?: (file: File) => boolean;
  onDragEnter?: () => void;
  onDragLeave?: () => void;
  onDrop?: () => void;
}

export function useDragAndDrop({ 
  onFilesDrop, 
  fileFilter = () => true,
  onDragEnter,
  onDragLeave,
  onDrop
}: DragAndDropOptions) {
  const [isDragging, setIsDragging] = useState(false);
  // Use useRef instead of state to avoid re-renders during drag operations
  const dragCounter = useRef(0);

  const handleDragIn = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Increment counter
    dragCounter.current += 1;
    
    // Only trigger the effect once when first entering
    if (dragCounter.current === 1) {
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        setIsDragging(true);
        onDragEnter?.();
      }
    }
  }, [onDragEnter]);

  const handleDragOut = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Decrement counter
    dragCounter.current -= 1;
    
    // Only trigger the effect when all drags are out
    if (dragCounter.current === 0) {
      setIsDragging(false);
      onDragLeave?.();
    }
  }, [onDragLeave]);

  const handleDrag = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    // Just prevent default behavior during drag
  }, []);

  const handleDrop = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Reset counter and state when dropping
    dragCounter.current = 0;
    setIsDragging(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const filteredFiles = Array.from(e.dataTransfer.files).filter(fileFilter);
      if (filteredFiles.length > 0) {
        onFilesDrop(filteredFiles);
        // e.dataTransfer.clearData();
      }
    }
    onDrop?.();
  }, [fileFilter, onFilesDrop, onDrop]);

  const dragHandlers = {
    onDragEnter: handleDragIn,
    onDragLeave: handleDragOut,
    onDragOver: handleDrag,
    onDrop: handleDrop,
  };

  return { isDragging, dragHandlers };
}
