const LIKED_MESSAGES_KEY = 'liked_messages';

export function getLikedMessages(): Set<string> {
    const stored = localStorage.getItem(LIKED_MESSAGES_KEY);
    if (!stored) return new Set();
    try {
        return new Set(JSON.parse(stored));
    } catch {
        return new Set();
    }
}

export function toggleLikedMessage(messageId: string): boolean {
    const likedMessages = getLikedMessages();
    const wasLiked = likedMessages.has(messageId);
    
    if (wasLiked) {
        likedMessages.delete(messageId);
    } else {
        likedMessages.add(messageId);
    }
    
    localStorage.setItem(LIKED_MESSAGES_KEY, JSON.stringify(Array.from(likedMessages)));
    return !wasLiked; // returns new state (true if now liked, false if now unliked)
}

export function isMessageLiked(messageId: string): boolean {
    return getLikedMessages().has(messageId);
}
