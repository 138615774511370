import { Monitor, Cpu, Globe2 } from 'lucide-react';
import { getBrowserInfo } from '../utils/browserInfo';

export function SystemInfo() {
    const getOsInfo = () => {
        const platform = navigator.userAgent;
        let os = platform;
        
        if (platform.includes("Win")) os = "Windows";
        else if (platform.includes("Mac")) os = "macOS";
        else if (platform.includes("Linux")) os = "Linux";
        else if (platform.includes("Android")) os = "Android";
        else if (platform.includes("iOS")) os = "iOS";

        return {
            name: os,
            userAgent: navigator.userAgent
        };
    };

    const getDisplayInfo = () => {
        return {
            screenResolution: `${window.screen.width}x${window.screen.height}`,
            colorDepth: `${window.screen.colorDepth}-bit`,
            pixelRatio: window.devicePixelRatio,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
    };

    const osInfo = getOsInfo();
    const browserInfo = getBrowserInfo();
    const displayInfo = getDisplayInfo();

    return (
        <div className="space-y-4 h-full">
            <div className="grid gap-4 overflow-y-auto max-h-[70vh] pr-2 pb-2">
                {/* OS Info Section */}
                <div className="p-4 border rounded-lg dark:border-gray-700">
                    <div className="flex items-center gap-2 mb-3">
                        <Monitor size={20} />
                        <h3 className="font-medium">Operating System</h3>
                    </div>
                    <div className="text-gray-600 dark:text-gray-400 space-y-2">
                        <p>Name: {osInfo.name}</p>
                        <p>Platform: {osInfo.userAgent}</p>
                    </div>
                </div>
                
                {/* Browser Info Section */}
                <div className="p-4 border rounded-lg dark:border-gray-700">
                    <div className="flex items-center gap-2 mb-3">
                        <Globe2 size={20} />
                        <h3 className="font-medium">Browser</h3>
                    </div>
                    <div className="text-gray-600 dark:text-gray-400 space-y-2">
                        <p>Name: {browserInfo.name}</p>
                        <p>Version: {browserInfo.version}</p>
                        <p>Language: {browserInfo.language}</p>
                        <p>Cookies: {browserInfo.cookies}</p>
                        <p>Mobile: {(browserInfo.mobile ? "Yes" : "No")}</p>
                        <p>Legacy Mode: {(browserInfo.legacyMode ? "Yes" : "No")}</p>
                    </div>
                </div>
                
                {/* Display Info Section */}
                <div className="p-4 border rounded-lg dark:border-gray-700">
                    <div className="flex items-center gap-2 mb-3">
                        <Cpu size={20} />
                        <h3 className="font-medium">System</h3>
                    </div>
                    <div className="text-gray-600 dark:text-gray-400 space-y-2">
                        <p>Screen Resolution: {displayInfo.screenResolution}</p>
                        <p>Color Depth: {displayInfo.colorDepth}</p>
                        <p>Pixel Ratio: {displayInfo.pixelRatio}</p>
                        <p>Timezone: {displayInfo.timezone}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
