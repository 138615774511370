import { activationCache } from "../db/activation-cache";

export async function resolveActivationBytes(checksum: string): Promise<string> {
  try {
    // First, try to get from cache
    const cachedBytes = await activationCache.get(checksum);
    if (cachedBytes) {
      console.log("Found activation bytes in cache");
      return cachedBytes;
    }

    console.log("Cache miss, fetching from API");
    const response = await fetch(
      `https://aaxapiserverfunction20220831180001.azurewebsites.net/api/v2/Activation/${checksum}?JonasIstCool=true`
    );

    if (!response.ok) {
      return "ERROR";
    }

    const data = await response.json();
    console.log("Activation bytes from API:", data);

    // Type guard
    if (typeof data.activationBytes !== "string") {
      throw new Error("Invalid activation bytes");
    }

    // Cache the result
    await activationCache.set(checksum, data.activationBytes);

    return data.activationBytes;
  } catch (error) {
    console.error("Error resolving activation bytes:", error);
    return "ERROR";
  }
}

function buf2hex(buffer: ArrayBuffer) {
  // buffer is an ArrayBuffer
  return Array.prototype.map
    .call(new Uint8Array(buffer), (x) => ("00" + x.toString(16)).slice(-2))
    .join("");
}
export async function extractChecksum(file: File) {
  const slic = file.slice(653, 653 + 20);
  return buf2hex(await slic.arrayBuffer());
}

/*
{
    "success": true,
    "message": "",
    "checksum": "a4cfea52649d6efc12c5174b6b51dd523f102fa1",
    "activationBytes": "9f786605"
}
*/
