import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Heart } from 'lucide-react';
import confetti from 'canvas-confetti';

interface DonationSuccessModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export function DonationSuccessModal({ isOpen, onClose }: DonationSuccessModalProps) {
    useEffect(() => {
        const handleEscape = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                onClose();
            }
        };

        if (isOpen) {
            window.addEventListener('keydown', handleEscape);
            // Fire confetti when modal opens
            const duration = 3000;
            const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };
            
            function randomInRange(min: number, max: number) {
                return Math.random() * (max - min) + min;
            }

            const interval = setInterval(() => {
                const particleCount = 50;
            
                // Create bursts of confetti
                confetti(Object.assign({}, defaults, {
                    particleCount,
                    origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
                }));
                confetti(Object.assign({}, defaults, {
                    particleCount,
                    origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
                }));
            }, 250);
            
            // Stop the animation after the duration
            const timer = setTimeout(() => {
                clearInterval(interval);
            }, duration);

            // Clean up timers and event listeners
            return () => {
                clearInterval(interval);
                clearTimeout(timer);
                window.removeEventListener('keydown', handleEscape);
            };
        }
    }, [isOpen]);

    return (
        <AnimatePresence>
            {isOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center">
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed inset-0 bg-black/50 backdrop-blur-sm"
                        onClick={onClose}
                    />
                    <motion.div
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.8 }}
                        className="relative z-50 w-[95%] max-w-lg rounded-lg bg-white p-6 shadow-xl dark:bg-gray-800"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex items-center justify-between mb-4">
                            <h2 className="text-2xl font-semibold dark:text-white flex items-center gap-2">
                                <Heart className="text-red-500" fill="currentColor" />
                                Thank You!
                                <Heart className="text-red-500" fill="currentColor" />
                            </h2>
                            <button
                                onClick={onClose}
                                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                            >
                                <X size={24} />
                            </button>
                        </div>

                        <div className="space-y-4 text-gray-600 dark:text-gray-300">
                            <p className="text-lg font-medium">
                                Thank you for supporting Audible Tools! ❤️
                            </p>
                            
                            <p>
                                Your contribution helps us continue providing free tools
                                and developing new features for the community.
                            </p>

                            <div className="bg-blue-50 dark:bg-blue-900/30 p-4 rounded-lg">
                                <p className="text-blue-700 dark:text-blue-300 text-center font-medium">
                                    We truly appreciate your support!
                                </p>
                            </div>
                        </div>
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    );
}
