import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { analytics } from '../utils/analytics';
import { readMediaInfo } from '../utils/mediaInfoExtractor';
import { extractChecksum, resolveActivationBytes } from '../utils/activationResolver';
import { EnrichedFile } from '../models/EnrichedFile';

interface FileContextState {
  files: EnrichedFile[];
  currentFileIndex: number;
  currentFile: EnrichedFile | null;
  currentFilename: string | null;
  checksum: string;
  activationBytes: string;
  deletionQueue: string[];
  showSizeWarning: boolean;
  pendingLargeFiles: File[];
}

interface FileContextActions {
  addFiles: (files: File[]) => void;
  removeFile: (filename: string) => void;
  queueFileForDeletion: (filename: string) => void;
  setCurrentFilename: (filename: string) => void;
  setCurrentFileIndex: (index: number) => void;
  reset: () => void;
  setChecksum: (checksum: string) => void;
  setActivationBytes: (bytes: string) => void;
  getFileIndex: (filename: string) => number;
  setShowSizeWarning: (show: boolean) => void;
  setPendingLargeFiles: (files: File[]) => void;
  processFiles: (files: File[]) => void;
  processLargeFiles: () => void;
}

const FileContext = createContext<FileContextState & FileContextActions | null>(null);

export function useFileManager() {
  const context = useContext(FileContext);
  if (!context) {
    throw new Error('useFileManager must be used within a FileProvider');
  }
  return context;
}

export function FileProvider({ children }: { children: React.ReactNode }) {
  const [files, setFiles] = useState<EnrichedFile[]>([]);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const [currentFilename, setCurrentFilename] = useState<string | null>(null);
  const [checksum, setChecksum] = useState("");
  const [activationBytes, setActivationBytes] = useState("");
  const [deletionQueue, setDeletionQueue] = useState<string[]>([]);
  const [showSizeWarning, setShowSizeWarning] = useState(false);
  const [pendingLargeFiles, setPendingLargeFiles] = useState<File[]>([]);

  // Helper function to get file index by filename
  const getFileIndex = useCallback((filename: string) => {
    return files.findIndex(f => f.originalFile.name === filename);
  }, [files]);

  const addOrUpdateFile = useCallback(
    (update: Partial<EnrichedFile> & { originalFile: File }) => {
      setFiles((prev) => {
        const existingFileIndex = prev.findIndex(
          (f) => f.originalFile.name === update.originalFile.name
        );

        const fileExists = existingFileIndex >= 0;

        const updatedFile: EnrichedFile = fileExists
          ? { ...prev[existingFileIndex], ...update }
          : {
              originalFile: update.originalFile,
              friendlyName: update.friendlyName || update.originalFile.name,
              info: update.info || {},
              Checksum: update.Checksum,
              ActivationBytes: update.ActivationBytes,
            };

        if (fileExists) {
          const newFiles = [...prev];
          newFiles[existingFileIndex] = updatedFile;
          return newFiles;
        }

        return [...prev, updatedFile];
      });
    },
    []
  );

  const enrichTrackInfo = useCallback(
    async (file: File) => {
      try {
        addOrUpdateFile({ originalFile: file, info: {}, friendlyName: file.name });
        const info = await readMediaInfo(file);
        addOrUpdateFile({
          originalFile: file,
          info,
          friendlyName: info.Title || file.name,
        });

        analytics.trackEvent("FileInfoEnriched", {
          hasTitle: !!info.Title,
          fileSize: file.size,
        });
      } catch (error) {
        console.error("Failed to enrich file info:", error);
        analytics.trackEvent("FileInfoEnrichmentFailed", {
          error: error instanceof Error ? error.message : "Unknown error",
        });
      }
    },
    [addOrUpdateFile]
  );

  const resolveChecksumForFile = useCallback(
    async (file: File) => {
      const checksumValue = await extractChecksum(file);
      addOrUpdateFile({ originalFile: file, Checksum: checksumValue });
      const activationBytesValue = await resolveActivationBytes(checksumValue);
      addOrUpdateFile({ originalFile: file, ActivationBytes: activationBytesValue });
    },
    [addOrUpdateFile]
  );

  const processFiles = useCallback(
    async (aaxFiles: File[]) => {
      if (aaxFiles.length === 0) return;

      const firstFileIndex = files.findIndex(
        (f) => f.originalFile.name === aaxFiles[0]?.name
      );

      if (firstFileIndex >= 0) {
        setCurrentFileIndex(firstFileIndex);
      } else {
        setCurrentFileIndex(files.length);
      }

      for (const file of aaxFiles) {
        const existingFileIndex = files.findIndex(
          (f) => f.originalFile.name === file.name
        );
        
        if (existingFileIndex >= 0) continue;

        await Promise.all([
          enrichTrackInfo(file),
          resolveChecksumForFile(file)
        ]);
      }

      analytics.trackEvent("FilesAddedToCarousel", {
        count: aaxFiles.length,
        startIndex: files.length,
      });
    },
    [files, enrichTrackInfo, resolveChecksumForFile]
  );

  const processLargeFiles = useCallback(async () => {
    if (pendingLargeFiles.length === 0) return;
    
    // Track the processing of large files
    analytics.trackEvent("ProcessingLargeFiles", {
      fileCount: pendingLargeFiles.length,
      totalSize: pendingLargeFiles.reduce((sum, file) => sum + file.size, 0)
    });
    
    // Process the files
    await processFiles(pendingLargeFiles);
    
    // Clear the pending files
    setPendingLargeFiles([]);
  }, [pendingLargeFiles, processFiles]);

  const addFiles = useCallback(
    async (newFiles: File[]) => {
      // Reset warning state at the start of new file addition
      setShowSizeWarning(false);
      setPendingLargeFiles([]);

      const aaxFiles = newFiles.filter((file) =>
        file.name.toLowerCase().endsWith('.aax')
      );

      if (aaxFiles.length === 0) {
        console.warn("No supported files dropped.");
        analytics.trackEvent("FileDropRejected", {
          reason: "UnsupportedFileType",
        });
        return;
      }

      // Check for large files (600MB = 600 * 1024 * 1024 bytes)
      const largeFiles = aaxFiles.filter(file => file.size > 600 * 1024 * 1024);
      const normalFiles = aaxFiles.filter(file => file.size <= 600 * 1024 * 1024);

      console.log('Processing files:', {
        total: aaxFiles.length,
        large: largeFiles.length,
        normal: normalFiles.length
      });

      // Track accepted files
      aaxFiles.forEach((file) => {
        analytics.trackEvent("FileDropAccepted", {
          fileSize: file.size,
          fileName: file.name,
          isLarge: file.size > 600 * 1024 * 1024
        });
      });

      // Process normal sized files immediately
      if (normalFiles.length > 0) {
        await processFiles(normalFiles);
      }

      // Queue large files for warning
      if (largeFiles.length > 0) {
        setPendingLargeFiles(largeFiles);
        setShowSizeWarning(true);
        analytics.trackEvent("LargeFileWarningShown", {
          fileCount: largeFiles.length,
          totalSize: largeFiles.reduce((sum, file) => sum + file.size, 0)
        });
      }
    },
    [files, enrichTrackInfo, resolveChecksumForFile]
  );

  // Update currentFilename when index changes
  useEffect(() => {
    if (files[currentFileIndex]) {
      setCurrentFilename(files[currentFileIndex].originalFile.name);
    } else {
      setCurrentFilename(null);
    }
  }, [currentFileIndex, files]);

  const queueFileForDeletion = useCallback(
    (filename: string) => {
      setDeletionQueue(prev => [...prev, filename]);
    },
    []
  );

  const removeFile = useCallback(
    (filename: string) => {
      const index = getFileIndex(filename);
      if (index === -1) return;

      setFiles(prev => {
        const newFiles = prev.filter(f => f.originalFile.name !== filename);

        // Remove from deletion queue
        setDeletionQueue(queue => queue.filter(name => name !== filename));

        // After removing the file, adjust the current index if needed
        if (newFiles.length > 0) {
          if (index === currentFileIndex) {
            // Stay at same index unless it was the last file
            if (currentFileIndex >= newFiles.length) {
              setCurrentFileIndex(Math.max(0, newFiles.length - 1));
            }
          } else if (index < currentFileIndex) {
            // If removing a file before current, decrement the index
            setCurrentFileIndex(currentFileIndex - 1);
          }
        } else {
          // No files left, reset to 0
          setCurrentFileIndex(0);
        }
        
        return newFiles;
      });
    },
    [currentFileIndex, getFileIndex]
  );

  const reset = useCallback(() => {
    setFiles([]);
    setChecksum("");
    setActivationBytes("");
    setCurrentFileIndex(0);
    window.history.pushState({}, "", "/");
  }, []);

  const value = {
    files,
    currentFileIndex,
    currentFile: files[currentFileIndex] || null,
    currentFilename,
    checksum,
    activationBytes,
    addFiles,
    queueFileForDeletion,
    removeFile,
    deletionQueue,
    setCurrentFileIndex,
    setCurrentFilename,
    getFileIndex,
    reset,
    setChecksum,
    setActivationBytes,
    showSizeWarning,
    setShowSizeWarning,
    pendingLargeFiles,
    setPendingLargeFiles,
    processFiles,
    processLargeFiles,
  };

  return <FileContext.Provider value={value}>{children}</FileContext.Provider>;
}
