import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Check } from 'lucide-react';
import { SupportButtons } from './SupportButtons';
import confetti from 'canvas-confetti';

interface ConversionCompleteModalProps {
    isOpen: boolean;
    onClose: () => void;
    onMessageClick?: () => void;
    fileCount: number;
}

export function ConversionCompleteModal({ isOpen, onClose, onMessageClick, fileCount }: ConversionCompleteModalProps) {
    console.log("ConversionCompleteModal render - isOpen:", isOpen, "fileCount:", fileCount);
    
    useEffect(() => {
        if (isOpen) {
            console.log("ConversionCompleteModal is now open");
            
            // Handle escape key
            const handleEscape = (e: KeyboardEvent) => {
                if (e.key === 'Escape') {
                    onClose();
                }
            };
            window.addEventListener('keydown', handleEscape);

            // Fire confetti when modal opens
            const defaults = { startVelocity: 15, spread: 500, ticks: 60, zIndex: 0 };
            
            function randomInRange(min: number, max: number) {
                return Math.random() * (max - min) + min;
            }

            const interval = setInterval(() => {
                const particleCount = 50;
            
                // Create bursts of confetti
                confetti(Object.assign({}, defaults, {
                    particleCount,
                    origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
                }));
                confetti(Object.assign({}, defaults, {
                    particleCount,
                    origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
                }));
            }, 400);
            
           

            return () => {
                window.removeEventListener('keydown', handleEscape);
                clearInterval(interval);
            };
        }
    }, [isOpen, onClose]);

    return (
        <AnimatePresence>
            {isOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-hidden">
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed inset-0 bg-black/50 backdrop-blur-sm"
                        onClick={onClose}
                    />
                    <motion.div
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.8 }}
                        className="relative z-50 w-[95%] max-w-lg max-h-[80vh] rounded-lg bg-white p-6 shadow-xl dark:bg-gray-800 overflow-y-auto"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex items-center justify-between mb-4">
                            <h2 className="text-2xl font-semibold dark:text-white flex items-center gap-2">
                                🎉Success! 
                            </h2>
                            <button
                                onClick={onClose}
                                className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                            >
                                <X size={24} />
                            </button>
                        </div>

                        <div className="space-y-4 text-gray-600 dark:text-gray-300">
                            <p className="text-lg">
                                Your audiobook{fileCount > 1 ? 's have' : ' has'} been successfully converted!
                            </p>

                            <div className="bg-blue-50 dark:bg-blue-900/30 p-4 rounded-lg">
                                <p className="text-center mb-4">
                                    If you find this tool helpful, please consider supporting its continued development.
                                </p>
                                <SupportButtons 
                                    onMessageClick={onMessageClick}
                                    showGithub={false}
                                    layout="compact"
                                />
                            </div>
                        </div>
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    );
}
