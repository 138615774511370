export const getBrowserInfo = () => {
    const ua = navigator.userAgent;
    let browserName = "Unknown";
    let version = "";

    if (ua.includes("Firefox/")) {
        browserName = "Firefox";
        version = ua.split("Firefox/")[1];
    } else if (ua.includes("Chrome/")) {
        browserName = "Chrome";
        version = ua.split("Chrome/")[1].split(" ")[0];
    } else if (ua.includes("Safari/") && !ua.includes("Chrome")) {
        browserName = "Safari";
        version = ua.split("Version/")[1].split(" ")[0];
    } else if (ua.includes("Edge/")) {
        browserName = "Edge";
        version = ua.split("Edge/")[1];
    }

    const isMobile = /Mobi|Android/i.test(ua);
    const legacyMode = isMobile && browserName === "Chrome";

    return {
        name: browserName,
        version: version,
        language: navigator.language,
        cookies: navigator.cookieEnabled ? "Enabled" : "Disabled",
        mobile: isMobile,
        legacyMode: legacyMode
    };
};
