import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import Plausible from 'plausible-tracker';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();

class Analytics {
    private plausible: ReturnType<typeof Plausible>;
    private appInsights: ApplicationInsights;

    constructor() {
        // Initialize Plausible
        this.plausible = Plausible({
            // domain: 'audible-tools.kamsker.at',
            // domain: 'localhost',
            domain: import.meta.env.VITE_PLAUSIBLE_DOMAIN || 'audible-tools.kamsker.at',
            trackLocalhost: true,
            apiHost: 'https://trace.kamsker.at',
            hashMode: true,
        });

        // Initialize Application Insights with existing config
        this.appInsights = new ApplicationInsights({
            config: {
                connectionString: 'InstrumentationKey=9c929efe-28ab-4182-96c4-b4719d3bec1a;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=63730512-1bd2-4dbc-92cc-ee7424a71ae8',
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: { history: browserHistory }
                },
                disableFetchTracking: false,
                enableCorsCorrelation: true,
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true
            }
        });

        // Start Application Insights
        this.appInsights.loadAppInsights();
        this.appInsights.trackPageView();

        // Enable automatic page view tracking for Plausible
        this.plausible.enableAutoPageviews();
    }

    isOnline() {
        return navigator.onLine;
    }

    trackEvent(name: string, properties?: { [key: string]: string | number | boolean }) {
        if (!this.isOnline()) {
            // console.warn('Tracking is disabled while offline');
            return;
        }

        // Track in Application Insights
        this.appInsights.trackEvent({ name, properties });

        // Track in Plausible with custom event
        if (properties) {
            // Convert properties to ensure all values are strings
            const plausibleProps = Object.entries(properties).reduce((acc, [key, value]) => {
                acc[key] = String(value);
                return acc;
            }, {} as Record<string, string>);
            this.plausible.trackEvent(name, { props: plausibleProps });
        } else {
            this.plausible.trackEvent(name);
        }
    }

    trackException(error: Error, properties?: { [key: string]: string | number | boolean }) {
        if (!this.isOnline()) {
            // console.warn('Tracking is disabled while offline');
            return;
        }

        // Track in Application Insights
        this.appInsights.trackException({ error, properties });

        // Track in Plausible as error event with properties
        const plausibleProps = {
            ...(properties ? Object.entries(properties).reduce((acc, [key, value]) => {
                acc[key] = String(value);
                return acc;
            }, {} as Record<string, string>) : {}),
            error: error.message,
            stack: error.stack || ''
        };
        this.plausible.trackEvent('error', { props: plausibleProps });
    }

    trackMetric(name: string, average: number, properties?: { [key: string]: string | number | boolean }) {
        if (!this.isOnline()) {
            // console.warn('Tracking is disabled while offline');
            return;
        }

        // Track in Application Insights
        this.appInsights.trackMetric({ name, average, properties });

        // Track in Plausible as metric event
        const plausibleProps = {
            ...(properties ? Object.entries(properties).reduce((acc, [key, value]) => {
                acc[key] = String(value);
                return acc;
            }, {} as Record<string, string>) : {}),
            value: String(average)
        };
        this.plausible.trackEvent(`metric_${name}`, { props: plausibleProps });
    }
}

// Export singleton instance
export const analytics = new Analytics();

// Export ReactPlugin for ErrorBoundary
export { reactPlugin };
