import { useState, useCallback } from 'react';

interface CarouselOptions {
  onSlideChange?: (newIndex: number) => void;
}

export function useCarousel(itemCount: number, currentIndex: number, options: CarouselOptions = {}) {
  const [slideDirection, setSlideDirection] = useState<'left' | 'right'>('right');

  const goToNext = useCallback(() => {
    if (itemCount === 0) return;
    const newIndex = (currentIndex + 1) % itemCount;
    setSlideDirection('left');
    options.onSlideChange?.(newIndex);
  }, [itemCount, currentIndex, options]);

  const goToPrevious = useCallback(() => {
    if (itemCount === 0) return;
    const newIndex = (currentIndex - 1 + itemCount) % itemCount;
    setSlideDirection('right');
    options.onSlideChange?.(newIndex);
  }, [itemCount, currentIndex, options]);

  const goToIndex = useCallback((index: number) => {
    if (index === currentIndex || index >= itemCount) return;
    setSlideDirection(index > currentIndex ? 'left' : 'right');
    options.onSlideChange?.(index);
  }, [currentIndex, itemCount, options]);

  return {
    slideDirection,
    goToNext,
    goToPrevious,
    goToIndex
  };
}
