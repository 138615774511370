import { useEffect, useState } from 'react';
import { ThreadedMessage, fetchMessages, toggleMessageLike, getMessageLikeCount } from '../utils/supabase';
import { ChevronLeft, ChevronRight, Heart, MessageSquare } from 'lucide-react';
import { MessageForm } from './MessageForm';
import { isMessageLiked, toggleLikedMessage } from '../utils/likeStorage';

const MESSAGES_PER_PAGE = 3;

interface MessageCardProps {
    message: ThreadedMessage;
    onReply: (msg: { id: string; message: string; name: string | null }) => void;
    depth?: number;
}

function MessageCard({ message, onReply, depth = 0 }: MessageCardProps) {
    const [likeCount, setLikeCount] = useState(message.likes || 0);
    const [isLiked, setIsLiked] = useState(isMessageLiked(message.id));

    // Handle storage events from other tabs
    // useEffect(() => {
    //     const handleStorageChange = async (event: StorageEvent) => {
    //         if (event.key === 'liked_messages') {
    //             const newIsLiked = isMessageLiked(message.id);
    //             setIsLiked(newIsLiked);
                
    //             // Fetch current like count without modifying it
    //             try {
    //                 const currentCount = await getMessageLikeCount(message.id);
    //                 setLikeCount(currentCount);
    //             } catch (error) {
    //                 console.error('Failed to fetch like count:', error);
    //             }
    //         }
    //     };

    //     window.addEventListener('storage', handleStorageChange);
    //     return () => window.removeEventListener('storage', handleStorageChange);
    // }, [message.id]);

    // Initial like count fetch
    useEffect(() => {
        const fetchLikeCount = async () => {
            try {
                const currentCount = await getMessageLikeCount(message.id);
                setLikeCount(currentCount);
            } catch (error) {
                console.error('Failed to fetch initial like count:', error);
            }
        };
        fetchLikeCount();
    }, [message.id]);

    const handleLike = async () => {
        // Double-check current localStorage state before proceeding
        const freshIsLiked = isMessageLiked(message.id);
        if (freshIsLiked !== isLiked) {
            // Local state is out of sync with storage, update UI
            setIsLiked(freshIsLiked);
            // sync like count with storage
            const currentCount = await getMessageLikeCount(message.id);
            setLikeCount(currentCount);
            return;
        }

        const currentCount = likeCount;

        try {
            // Update localStorage first
            const newIsLiked = toggleLikedMessage(message.id);
            setIsLiked(newIsLiked);

            // Only proceed with API call if state actually changed
            if (newIsLiked !== freshIsLiked) {
                setLikeCount(currentCount + (newIsLiked ? 1 : -1));
                await toggleMessageLike(message.id, newIsLiked);
            }
        } catch (error) {
            console.error('Failed to toggle like:', error);
            // Revert UI and localStorage on error
            toggleLikedMessage(message.id); // Revert localStorage
            setIsLiked(freshIsLiked);
            setLikeCount(currentCount);
        }
    };

    return (
        <div className="space-y-4">
            <div className={`rounded-lg border border-gray-200 p-4 dark:border-gray-700 dark:bg-gray-800 ${depth > 0 ? 'border-l-4 border-l-blue-500 ml-4' : ''}`}>
                <div className="flex items-center justify-between mb-2">
                    <div className="font-medium text-gray-900 dark:text-white">
                        {message.name || 'Anonymous'}
                    </div>
                    <time className="text-sm text-gray-500 dark:text-gray-400">
                        {new Date(message.created_at).toLocaleDateString(undefined, {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                        })}
                    </time>
                </div>
                <p className="text-gray-700 dark:text-gray-300 whitespace-pre-wrap mb-3">
                    {message.message}
                </p>
                <div className="flex items-center gap-4">
                    <button
                        onClick={handleLike}
                        className="flex items-center gap-1 text-sm text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                    >
                        <Heart
                            size={16}
                            className={isLiked ? "fill-current text-red-500" : ""}
                        />
                        {likeCount}
                    </button>
                    <button
                        onClick={() => onReply({
                            id: message.id,
                            message: message.message,
                            name: message.name
                        })}
                        className="flex items-center gap-1 text-sm text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                    >
                        <MessageSquare size={16} />
                        Reply
                    </button>
                </div>
            </div>
            {message.replies && message.replies.length > 0 && (
                <div className="space-y-4">
                    {message.replies.map(reply => (
                        <MessageCard
                            key={reply.id}
                            message={reply}
                            onReply={onReply}
                            depth={depth + 1}
                        />
                    ))}
                </div>
            )}
        </div>
    );
}

export function MessageList() {
    const [messages, setMessages] = useState<ThreadedMessage[]>([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [replyTo, setReplyTo] = useState<{
        id: string;
        message: string;
        name: string | null;
    } | null>(null);

    const loadMessages = async () => {
        setLoading(true);
        try {
            const data = await fetchMessages();
            setMessages(data);
        } catch (error) {
            console.error('Failed to fetch messages:', error);
        }
        setLoading(false);
    };

    // Load messages initially and after sending a message
    useEffect(() => {
        loadMessages();
    }, []);

    const totalPages = Math.ceil(messages.length / MESSAGES_PER_PAGE);
    const startIndex = (currentPage - 1) * MESSAGES_PER_PAGE;
    const endIndex = startIndex + MESSAGES_PER_PAGE;
    const currentMessages = messages.slice(startIndex, endIndex);

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const goToPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    if (loading) {
        return (
            <div className="text-center py-8 text-gray-500 dark:text-gray-400">
                Loading messages...
            </div>
        );
    }

    if (messages.length === 0) {
        return (
            <div className="text-center py-8 text-gray-500 dark:text-gray-400">
                No messages yet. Be the first to leave a message!
            </div>
        );
    }

    return (
        <div className="flex flex-col min-h-0">
            <div className="space-y-4 overflow-y-auto max-h-[60vh] md:max-h-[70vh] mb-4 pr-2">
                {currentMessages.map((msg) => (
                    <MessageCard
                        key={msg.id}
                        message={msg}
                        onReply={setReplyTo}
                    />
                ))}
            </div>
            {replyTo && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                    <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-full max-w-lg">
                        <MessageForm
                            onSuccess={() => {
                                loadMessages();
                                setReplyTo(null);
                            }}
                            onCancel={() => setReplyTo(null)}
                            replyTo={replyTo}
                        />
                    </div>
                </div>
            )}
            <div className="flex items-center justify-between pt-4 border-t dark:border-gray-700">
                <button
                    onClick={goToPreviousPage}
                    disabled={currentPage === 1}
                    className="flex items-center gap-1 px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    <ChevronLeft size={16} />
                    Previous
                </button>
                <span className="text-sm text-gray-700 dark:text-gray-300">
                    Page {currentPage} of {totalPages}
                </span>
                <button
                    onClick={goToNextPage}
                    disabled={currentPage === totalPages}
                    className="flex items-center gap-1 px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 dark:bg-gray-800 dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                    Next
                    <ChevronRight size={16} />
                </button>
            </div>
        </div>
    );
}
