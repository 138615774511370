import React, { useCallback, useEffect, useRef, useState } from "react";
import { loadPreference, savePreference } from "../utils/localStorage";
import { ChevronDown } from "lucide-react";
import { analytics } from "../utils/analytics";
import { Check } from "lucide-react";
import { useTheme } from "../ThemeContext";
import { EnrichedFile } from "../models/EnrichedFile";
import { useFileManager } from "../contexts/FileContext";
import {
  downloadBlob,
  transcodeAax,
  transcodeAaxWorkerFs
} from "../utils/converter";
import { getBrowserInfo } from "../utils/browserInfo";

type ConversionProgressProps = {
  file: EnrichedFile | null;
  onConversionStart?: () => void;
  onConversionComplete?: (fileCount: number) => void;
  onCancel?: () => void;
};

export function AudioConverter({ file, onConversionStart, onConversionComplete, onCancel }: ConversionProgressProps) {
  const { isDark } = useTheme();
  const {
    files,
    setCurrentFileIndex,
  } = useFileManager();

  const [progress, setProgress] = useState(0);
  const [status, setStatusTmp] = useState<"idle" | "converting" | "done" | "cancelled" | "downloading" | "error">("idle");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isBatchConverting, setIsBatchConverting] = useState(false);
  const [currentBatchIndex, setCurrentBatchIndex] = useState(0);
  const [successCount, setSuccessCount] = useState(0);

  const setStatus = (status: "idle" | "converting" | "done" | "cancelled" | "downloading" | "error") => {
    console.log("Setting status:", status);
    setStatusTmp(status);
  };

  const validFormats = ['m4b', 'mp3', 'flac'] as const;
  type TargetFormat = typeof validFormats[number];
  
  const [targetFormat, setTargetFormat] = useState<TargetFormat>(() => 
    loadPreference('TARGET_FORMAT', 'm4b', (value) => validFormats.includes(value as TargetFormat)) as TargetFormat
  );

  useEffect(() => {
    savePreference('TARGET_FORMAT', targetFormat);
  }, [targetFormat]);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const progressInterval = useRef<number | null>(null);
  const isMounted = useRef(true);
  const isConvertingRef = useRef(false);
  const abortControllerRef = useRef<AbortController | null>(null);
  const cancelTimeoutRef = useRef<number | null>(null);
  const finallyTimeoutRef = useRef<number | null>(null);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
      if (progressInterval.current) {
        window.clearInterval(progressInterval.current);
      }
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      if (cancelTimeoutRef.current) {
        window.clearTimeout(cancelTimeoutRef.current);
      }
      if (finallyTimeoutRef.current) {
        window.clearTimeout(finallyTimeoutRef.current);
      }
    };
  }, []);

  // Helper function to process a single file
  const processSingleFile = useCallback(async (
    targetFile: EnrichedFile,
    fileAbortController: AbortController
  ): Promise<boolean> => {
    if (!targetFile || !targetFile.ActivationBytes) return false;
    
    setProgress(0);
    
    analytics.trackEvent("ConversionStarted", {
      fileName: targetFile.originalFile.name,
      fileSize: targetFile.originalFile.size,
      hasTitle: !!targetFile.info.Title
    });

    try {
      // throw new Error("you baddie");

      const { legacyMode } = getBrowserInfo();
      const transcodeFunction = legacyMode ? transcodeAax : transcodeAaxWorkerFs;
      
      const data = await transcodeFunction(
        targetFile.originalFile,
        targetFile.ActivationBytes,
        (progress: number) => {
          const progressWithDecimals = progress.toFixed(2) as unknown as number;
          setProgress(progressWithDecimals);
          
          if (progressWithDecimals >= 25 && progressWithDecimals < 26 ||
              progressWithDecimals >= 50 && progressWithDecimals < 51 ||
              progressWithDecimals >= 75 && progressWithDecimals < 76) {
            analytics.trackEvent("ConversionProgress", {
              fileName: targetFile.originalFile.name,
              progress: Math.floor(progressWithDecimals)
            });
          }
        },
        targetFormat,
        fileAbortController.signal
      );

      analytics.trackEvent("ConversionProgress", {
        fileName: targetFile.originalFile.name,
        progress: 100
      });

      if (fileAbortController.signal.aborted) {
        return false;
      }

      setProgress(100);
      const outputFileName = targetFile.originalFile.name.replace(/\.aax$/, `.${targetFormat}`);
      downloadBlob(data, outputFileName);
      
      analytics.trackEvent("ConversionCompleted", {
        fileName: targetFile.originalFile.name,
        outputFileName,
        duration: targetFile.info.Duration || 0,
        fileSize: targetFile.originalFile.size
      });
      
      return true;
    } catch (error) {
      console.error("Conversion error:", error);
      if ((error as Error).message !== 'Conversion cancelled') {
        analytics.trackException(error as Error, {
          fileName: targetFile.originalFile.name,
          fileSize: targetFile.originalFile.size
        });

        // Clear any existing timeouts
        if (cancelTimeoutRef.current) {
          window.clearTimeout(cancelTimeoutRef.current);
          cancelTimeoutRef.current = null;
        }
        if (finallyTimeoutRef.current) {
          window.clearTimeout(finallyTimeoutRef.current);
          finallyTimeoutRef.current = null;
        }

        // set error state and reset to idle after a delay
        setErrorMessage((error as Error).message || "An error occurred during conversion");
        setStatus("error");
        isConvertingRef.current = false;
        
        // Use our tracked timeout instead of setTimeout directly
        cancelTimeoutRef.current = window.setTimeout(() => {
          if (isMounted.current) {
            setStatus("idle");
            setProgress(0);
            setErrorMessage("");
          }
          cancelTimeoutRef.current = null;
        }, 3000);
      }
      return false;
    }
  }, [targetFormat]);

  // Unified conversion function that handles both single and batch conversions
  const convertFiles = useCallback(async (targetFiles: EnrichedFile[] = []) => {
    // Ensure we're not already converting
    if (isConvertingRef.current) return;
    
    // Clear any pending timeouts from previous operations
    if (cancelTimeoutRef.current) {
      window.clearTimeout(cancelTimeoutRef.current);
      cancelTimeoutRef.current = null;
    }
    if (finallyTimeoutRef.current) {
      window.clearTimeout(finallyTimeoutRef.current);
      finallyTimeoutRef.current = null;
    }

    // If no files are provided and we have a current file, convert just that one
    if (targetFiles.length === 0 && file) {
      targetFiles = [file];
    }
    
    if (targetFiles.length === 0) {
      isConvertingRef.current = false; // Make sure we reset this if we're returning early
      return;
    }
    
    const isBatch = targetFiles.length > 1;
    
    // Create main abort controller
    const mainAbortController = new AbortController();
    abortControllerRef.current = mainAbortController;
    
    setIsBatchConverting(isBatch);
    setCurrentBatchIndex(0);
    setSuccessCount(0);
    isConvertingRef.current = true;
    setStatus("converting");
    
    onConversionStart?.();
    
    // Track successful conversions locally as well
    let localSuccessCount = 0;
    let hadError = false;
    
    try {
      for (let i = 0; i < targetFiles.length; i++) {
        if (mainAbortController.signal.aborted) {
          break;
        }

        setCurrentBatchIndex(i);
        if (isBatch) {
          setCurrentFileIndex(i);
        }
        
        // Create a fresh abort controller that's linked to the main controller
        const fileAbortController = new AbortController();
        const originalAbort: AbortController | null = abortControllerRef.current;
        abortControllerRef.current = fileAbortController;
        
        // If main abort happens, also abort the current file
        const abortHandler = () => {
          fileAbortController.abort();
        };
        mainAbortController.signal.addEventListener('abort', abortHandler);
        
        const success = await processSingleFile(targetFiles[i], fileAbortController);
        if (success) {
          localSuccessCount++;
          setSuccessCount(prev => prev + 1);
        } else if (!success && !fileAbortController.signal.aborted) {
          // If there was a conversion error (not cancellation), break the loop
          hadError = true;
          break;
        }
        
        // Remove listener
        mainAbortController.signal.removeEventListener('abort', abortHandler);
        // Restore the main abort controller
        abortControllerRef.current = originalAbort;
        
        if (!success || mainAbortController.signal.aborted) {
          break;
        }
        
        // If there are more files to process
        if (i < targetFiles.length - 1) {
          setStatus("downloading");
          setProgress(0);
          
          // Wait a moment before next file
          await new Promise<void>(resolve => {
            const timeout = setTimeout(() => {
              clearTimeout(timeout);
              resolve();
            }, 1000);
            
            const checkAbort = () => {
              if (mainAbortController.signal.aborted) {
                clearTimeout(timeout);
                resolve();
              }
            };
            
            mainAbortController.signal.addEventListener('abort', checkAbort, { once: true });
          });

          setStatus("converting");
          setProgress(0);
        }
      }
      
      // Set status to done only for single file conversion and if we haven't encountered an error
      if (!isBatch && !mainAbortController.signal.aborted && !hadError) {
        setStatus("done");
      }
      
    } catch (error) {
      console.error("Conversion error:", error);
      analytics.trackException(error as Error, { context: isBatch ? "batch-conversion" : "single-conversion" });
    } finally {
      isConvertingRef.current = false;
      abortControllerRef.current = null;
      setIsBatchConverting(false);
      
      if (isBatch) {
        setCurrentFileIndex(0);
      }
      
      // Call onConversionComplete with the total number of successfully converted files
      // Only if not aborted and we had a successful conversion
      if (!mainAbortController.signal.aborted) {
        console.log("Conversion finished - localSuccessCount:", localSuccessCount);
        if (localSuccessCount > 0) {
          console.log("Calling onConversionComplete with count:", localSuccessCount);
          onConversionComplete?.(localSuccessCount);
        }
      }
      
      // Reset the status
      if (mainAbortController.signal.aborted) {
        setStatus("cancelled");
      } else if (isBatch) {
        setStatus("idle");
      }
      // Not resetting status to idle for single file conversions preserves "done" state
      
      // Reset to idle after a delay only for cancellation or batch conversions
      if (mainAbortController.signal.aborted || isBatch) {
        if (cancelTimeoutRef.current) {
          window.clearTimeout(cancelTimeoutRef.current);
          cancelTimeoutRef.current = null;
        }
        
        finallyTimeoutRef.current = window.setTimeout(() => {
          if (isMounted.current) {
            setStatus("idle");
            setProgress(0);
          }
          finallyTimeoutRef.current = null;
        }, 1000);
      }
    }
  }, [file, onConversionStart, processSingleFile, setCurrentFileIndex, onConversionComplete]);

  const cancelConversion = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    
    // Clear any existing timeouts first
    if (cancelTimeoutRef.current) {
      window.clearTimeout(cancelTimeoutRef.current);
      cancelTimeoutRef.current = null;
    }
    if (finallyTimeoutRef.current) {
      window.clearTimeout(finallyTimeoutRef.current);
      finallyTimeoutRef.current = null;
    }
    
    setIsBatchConverting(false);
    setStatus("cancelled");
    
    if (file) {
      analytics.trackEvent("ConversionCancelled", {
        fileName: file.originalFile.name,
        progress: progress,
        wasDownloading: status === "downloading"
      });
    }

    if (progressInterval.current) {
      window.clearInterval(progressInterval.current);
      progressInterval.current = null;
    }
    
    // Set our tracked timeout
    cancelTimeoutRef.current = window.setTimeout(() => {
      if (isMounted.current) {
        setStatus("idle");
        setProgress(0);
        isConvertingRef.current = false;
      }
      cancelTimeoutRef.current = null;
    }, 1000);
    
    onCancel?.();
  }, [file, progress, status, onCancel]);

  useEffect(() => {
    if (!file) {
      cancelConversion();
    }
  }, [file, cancelConversion]);

  useEffect(() => {
    // If status changes to something other than converting/downloading but isConvertingRef is still true,
    // this suggests a potential state inconsistency
    if (status !== "converting" && status !== "downloading" && isConvertingRef.current) {
      // Only update the ref if we're in a terminal state
      if (status === "done" || status === "cancelled" || status === "error" || status === "idle") {
        isConvertingRef.current = false;
      }
    }
  }, [status]);

  const buttonText = isBatchConverting ? (
    status === "downloading" ? "Cancel Download" : 
    <>Converting {currentBatchIndex + 1}/{files.length}</>
  ) : status === "converting" ? (
    "Cancel Conversion"
  ) : status === "done" ? (
    <>
      <Check className="w-5 h-5" />
      Converted
    </>
  ) : status === "cancelled" ? (
    "Cancelled"
  ) : status === "error" ? (
    "Error"
  ) : files.length > 1 ? (
    "Convert All"
  ) : (
    "Convert"
  );

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const formats = validFormats;
  
  const mybutton = (
    <div className="relative" ref={dropdownRef}>
      <div className="flex">
        <button
          onClick={() => {
            if (status === "converting" || status === "downloading" || isBatchConverting) {
              cancelConversion();
            } else if (files.length > 1) {
              convertFiles(files);
            } else if (file) {
              convertFiles([file]);
            }
          }}
          disabled={status === "done" || status === "cancelled" || status === "error" || !file}
          className={`
            flex-grow py-3 pl-4 pr-2 rounded-l-lg font-medium text-white
            transition-all duration-200
            ${
              status === "converting" || status === "downloading" || isBatchConverting
                ? "bg-red-700 hover:bg-red-800"
                : status === "done" || status === "cancelled"
                ? "bg-gray-600 cursor-not-allowed"
                : status === "error"
                ? "bg-red-600 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-600"
            }
            flex items-center justify-center gap-2
          `}
        >
          {buttonText}
        </button>
        <button
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          disabled={status === "done" || status === "cancelled" || status === "error" || status === "converting" || status === "downloading" || isBatchConverting}
          className={`
            py-3 px-4 pl-7 rounded-r-lg font-medium text-white border-l border-white/20
            transition-all duration-200
            ${
              status === "converting" || status === "downloading" || isBatchConverting
                ? "bg-red-700 hover:bg-red-800"
                : status === "done" || status === "cancelled"
                ? "bg-gray-600 cursor-not-allowed"
                : status === "error"
                ? "bg-red-600 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-600"
            }
            flex items-center
          `}
        >
          <span className="mr-2">{targetFormat.toUpperCase()}</span>
          <ChevronDown className="w-5 h-5" />
        </button>
      </div>
      
      {isDropdownOpen && (
        <div className="absolute right-0 mt-1 w-24 rounded-lg shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          <div className="py-1">
            {formats.map((format) => (
              <button
                key={format}
                onClick={() => {
                  setTargetFormat(format);
                  setIsDropdownOpen(false);
                }}
                className={`
                  ${format === targetFormat ? 'bg-blue-500 text-white' : 'text-gray-900 dark:text-gray-100'}
                  group flex w-full items-center px-4 py-2 text-sm hover:bg-blue-500 hover:text-white
                `}
              >
                {format.toUpperCase()}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="space-y-4">
      <div className={`rounded-lg p-4 shadow-sm ${isDark ? "bg-gray-800" : "bg-white"}`}>
        <div className="mb-2 flex justify-between items-center">
          <span
            className={`text-sm font-medium ${
              status === "error" ? "text-red-500" :
              isDark ? "text-gray-200" : "text-gray-700"
            }`}
          >
            {status === "downloading" ? "Downloading..." :
              isBatchConverting && status === "converting" ? `Converting file ${currentBatchIndex + 1} of ${files.length}` :
              status === "converting" ? "Converting..." :
              status === "done" ? "Conversion Complete!" :
              status === "cancelled" ? "Conversion Cancelled" :
              status === "error" ? `Error: ${errorMessage}` :
              files.length > 1 ? `Ready to convert ${files.length} files` : "Ready to Convert"}
          </span>
          <span className="text-sm text-gray-500">
            {status === "cancelled" ? "Cancelled" : 
             status === "error" ? "Failed" : `${progress}%`}
          </span>
        </div>
        <div
          className={`w-full rounded-full h-2 ${isDark ? "bg-gray-700" : "bg-gray-200"}`}
        >
          <div
            className={`h-2 rounded-full transition-all duration-300 ${status === "error" ? "bg-red-500" : "bg-blue-500"}`}
            style={{ width: `${progress}%` }}
          />
        </div>
      </div>

      {mybutton}
    </div>
  );
}
