// import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";

import { FFFSType, FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";

// Map of output formats to their MIME types
const formatMimeTypes = {
  m4b: 'audio/mp4',
  mp3: 'audio/mpeg',
  flac: 'audio/flac'
} as const;


// Transcodes a given file from aax to the specified format with the given activation bytes using FFmpeg
export async function transcodeAax(
  file: File,
  activationBytes: string,
  progressCallback: (progress: number) => void,
  outputFormat: keyof typeof formatMimeTypes = 'm4b',
  signal?: AbortSignal
) {
  console.log("Loading FFmpeg...");
  // const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.10/dist/esm";
  const ffmpeg = new FFmpeg();


  ffmpeg.on("log", ({ message }) => {
    // messageRef.current.innerHTML = message;
    console.log(message);
  });

  await LoadFFMpegCore(ffmpeg);

  console.log("FFmpeg loaded.");

  // Set up abort handler
  if (signal) {
    signal.addEventListener('abort', () => {
      console.log("Terminating FFmpeg process...");
      ffmpeg.terminate();
      throw new Error('Conversion cancelled');
    });
  }

  // Write the file to the virtual file system
  await ffmpeg.writeFile("input.aax", await fetchFile(file));
  ffmpeg.on("progress", (e) => progressCallback(e.progress * 100));
  
  const outputFile = `output.${outputFormat}`;
  const ffmpegArgs = [
    "-activation_bytes",
    activationBytes,
    "-i",
    "input.aax"
  ];

  // Add format-specific encoding options
  if (outputFormat === 'mp3') {
    ffmpegArgs.push('-c:a', 'libmp3lame', '-q:a', '0'); // Best quality MP3
  } else if (outputFormat === 'flac') {
    ffmpegArgs.push('-c:a', 'flac'); // Lossless FLAC
  } else {
    ffmpegArgs.push('-c', 'copy'); // Direct stream copy for m4b
  }
  
  ffmpegArgs.push(outputFile);
  console.log("FFmpeg args:", ffmpegArgs);
  await ffmpeg.exec(ffmpegArgs);

  // Read the result
  const data = await ffmpeg.readFile(outputFile);
  await ffmpeg.deleteFile("input.aax");
  await ffmpeg.deleteFile(outputFile);
  return new Blob([data], { type: formatMimeTypes[outputFormat] });
}

async function LoadFFMpegCore(ffmpeg: FFmpeg) {
  // window.location.href but only the https://localhost:port/ part
  const currentURL = window.location.origin;
  // const coreURL = await toBlobURL(`${currentURL}/ffmpeg-core.js`, 'text/javascript');
  const coreURL = `${currentURL}/ffmpeg-core.js`;
  const wasmURL = `${currentURL}/ffmpeg-core.wasm`;

  // // await fetch("/ffmpeg-core.wasm").then(x=>x.blob())
  // const blob = await fetch("/ffmpeg-core.wasm").then((response) => {
  //   if (!response.ok) {
  //     throw new Error("Failed to fetch ffmpeg-core.wasm");
  //   }
  //   return response.blob();
  // });

  // const dataUrlHeader = "data:application/octet-stream;base64,";
  // const base64Data = await new Response(blob).text();

  // const dataUrl = await urlToDataURL(wasmURL);
  
 
  const time = performance.now();
  await ffmpeg.load({
    coreURL: coreURL,
    wasmURL: wasmURL,
  });
  const time2 = performance.now();
  console.log("FFmpeg core loaded in", time2 - time, "ms");
}

// convertsa a URL to a data URL beginning with data:application/octet-stream;base64,
async function urlToDataURL(url: string): Promise<string> {
  const response = await fetch(url);
  const originalBlob = await response.blob();

  const blob = new Blob([originalBlob], { type: "application/octet-stream" });

  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result as string); // This will now start with the desired MIME type
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(blob);
  });
}


// Transcodes a given file from aax using the worker filesystem approach
export async function transcodeAaxWorkerFs(
  file: File,
  activationBytes: string,
  progressCallback: (progress: number) => void,
  outputFormat: keyof typeof formatMimeTypes = 'm4b',
  signal?: AbortSignal
) {
  console.log("Loading FFmpeg...");
  const ffmpeg = new FFmpeg();
  ffmpeg.on("log", ({ message }) => {
    console.log(message);
  });

  await LoadFFMpegCore(ffmpeg);
  console.log("FFmpeg loaded.");

  // Set up abort handler
  if (signal) {
    signal.addEventListener('abort', () => {
      console.log("Terminating FFmpeg process...");
      ffmpeg.terminate();
      throw new Error('Conversion cancelled');
    });
  }

  const inputDir = "/input";
  const inputFile = `${inputDir}/${file.name}`;
  await ffmpeg.createDir(inputDir);
  await ffmpeg.mount(
    FFFSType.WORKERFS,
    {
      files: [file],
    },
    inputDir
  );

  ffmpeg.on("progress", (e) => progressCallback(e.progress * 100));
  
  const outputFile = `output.${outputFormat}`;
  const ffmpegArgs = [
    "-activation_bytes",
    activationBytes,
    "-i",
    inputFile
  ];

  // Add format-specific encoding options
  if (outputFormat === 'mp3') {
    ffmpegArgs.push('-c:a', 'libmp3lame', '-q:a', '0'); // Best quality MP3
  } else if (outputFormat === 'flac') {
    ffmpegArgs.push('-c:a', 'flac'); // Lossless FLAC
  } else {
    ffmpegArgs.push('-c', 'copy'); // Direct stream copy for m4b
  }
  
  ffmpegArgs.push(outputFile);
  console.log("FFmpeg args:", ffmpegArgs);
  
  await ffmpeg.exec(ffmpegArgs);

  const output = await ffmpeg.readFile(outputFile);

  await ffmpeg.unmount(inputDir);
  await ffmpeg.deleteDir(inputDir);
  await ffmpeg.deleteFile(outputFile);
  
  return new Blob([output], { type: formatMimeTypes[outputFormat] });
}

/*
   const outputFileName = file.originalFile.name.replace(/\.aax$/, ".m4b");
    const a = document.createElement("a");
    document.body.appendChild(a);
    const blob = data;
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = outputFileName;
    a.click();
    window.URL.revokeObjectURL(url);
*/

export function downloadBlob(blob: Blob, filename: string) {
  const a = document.createElement("a");
  document.body.appendChild(a);
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  // wait a moment to ensure the download starts, then revoke the URL
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    a.remove(); // Clean up the anchor element
  }, 500); // Adjust the timeout as needed
}



export interface Chapter {
  title: string;
  start: string;
  end: string;
}

export interface ChapterFile {
  name: string;
  blob: Blob;
}

export async function transcodeAaxToM4bWorkerFs1(
  file: File,
  activationBytes: string,
  progressCallback: (progress: number) => void,
  chapterCallback: (chapterFile: ChapterFile) => void
): Promise<void> {
  console.log("Loading FFmpeg...");
  const ffmpeg = new FFmpeg();
  ffmpeg.on("log", ({ message }: { message: string }) => {
    console.log(message);
  });

  // await ffmpeg.load();
  await LoadFFMpegCore(ffmpeg);
  console.log("FFmpeg loaded.");

  const inputDir = "/input";
  const inputFile = `${inputDir}/${file.name}`;
  await ffmpeg.createDir(inputDir);
  await ffmpeg.mount(
    FFFSType.WORKERFS,
    {
      files: [file],
    },
    inputDir
  );

  // Extract chapter metadata
  console.log("Extracting chapters...");
  const chapterMetadataFile = "chapters.txt";
  await ffmpeg.exec([
    "-activation_bytes",
    activationBytes,
    "-i",
    inputFile,
    "-f",
    "ffmetadata",
    chapterMetadataFile,
  ]);

  const chapterMetadata = await ffmpeg.readFile(chapterMetadataFile);
  const chapters = parseChaptersFromMetadata(chapterMetadata);
  console.log("Chapters extracted:", chapters);

  // Generate all chapter files in a single FFmpeg command
  console.log("Creating chapter files...");
  const chapterOutputDir = "/output";
  await ffmpeg.createDir(chapterOutputDir);

  const ffmpegArgs = ["-activation_bytes", activationBytes, "-i", inputFile];

  // Add chapter-specific output arguments
  chapters.forEach((chapter, index) => {
    const outputFileName = `${chapterOutputDir}/chapter_${index + 1}.m4b`;
    ffmpegArgs.push("-ss", chapter.start, "-to", chapter.end, "-c", "copy", outputFileName);
  });

  // Execute FFmpeg and monitor progress
  ffmpeg.on("progress", (e: { progress: number }) => {
    progressCallback(e.progress * 100);
  });

  await ffmpeg.exec(ffmpegArgs);

  // Handle completed chapter files
  for (let i = 0; i < chapters.length; i++) {
    const outputFileName = `${chapterOutputDir}/chapter_${i + 1}.m4b`;
    const output = await ffmpeg.readFile(outputFileName);
    const chapterFile: ChapterFile = {
      name: `chapter_${i + 1}.m4b`,
      blob: new Blob([output], { type: "audio/m4b" }),
    };

    // Call the chapter callback
    chapterCallback(chapterFile);

    // Clean up the file to free memory
    await ffmpeg.deleteFile(outputFileName);
  }

  // Clean up directories
  await ffmpeg.unmount(inputDir);
  await ffmpeg.deleteDir(inputDir);
  await ffmpeg.deleteDir(chapterOutputDir);

  console.log("All chapters processed.");
}


function parseChaptersFromMetadata(metadata: Uint8Array | string): Chapter[] {
  // Convert metadata to a string if it's a Uint8Array
  const metadataString = metadata instanceof Uint8Array
    ? new TextDecoder().decode(metadata)
    : metadata;

  const lines = metadataString.split("\n");
  const chapters: Chapter[] = [];
  let currentChapter: Partial<Chapter> | null = null;

  for (const line of lines) {
    if (line.startsWith("[CHAPTER]")) {
      // If there's an existing chapter, push it to the list
      if (currentChapter && currentChapter.start && currentChapter.end) {
        chapters.push(currentChapter as Chapter);
      }
      // Start a new chapter
      currentChapter = { title: "", start: "", end: "" };
    } else if (currentChapter && line.startsWith("title=")) {
      currentChapter.title = line.split("=")[1];
    } else if (currentChapter && line.startsWith("START=")) {
      currentChapter.start = formatTime(parseInt(line.split("=")[1], 10));
    } else if (currentChapter && line.startsWith("END=")) {
      currentChapter.end = formatTime(parseInt(line.split("=")[1], 10));
    }
  }

  // Add the last chapter if it's valid
  if (currentChapter && currentChapter.start && currentChapter.end) {
    chapters.push(currentChapter as Chapter);
  }

  return chapters;
}

// Helper to format time for FFmpeg
function formatTime(timeInTicks: number): string {
  const seconds = timeInTicks / 44100; // Convert TIMEBASE ticks to seconds
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);
  return `${hours}:${minutes.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
}
