import React, { useState, useEffect, useCallback } from "react";
import { analytics } from "./utils/analytics";
import { useFileHandler } from "./hooks/useFileHandler";
import { WarningModal } from "./components/WarningModal";
import AppInsightsErrorBoundary from "./components/AppInsightsErrorBoundary";
import { useTheme } from "./ThemeContext";
import { FileProvider, useFileManager } from "./contexts/FileContext";
import { Header } from "./components/Header";
import { DropZone } from "./components/DropZone";
import { HelpModal } from "./components/HelpModal";
import { ExpertMode } from "./components/ExpertMode";
import { AudioConverter } from "./components/AudioConverter";
import { useCarousel } from "./hooks/useCarousel";
import { FilePreview } from "./components/FilePreview";
import { NavigationControls } from "./components/NavigationControls";
import { FileIndicators } from "./components/FileIndicators";
import { motion, AnimatePresence } from "framer-motion";
import { useDragAndDrop } from "./hooks/useDragAndDrop";
import { DonationModal } from "./components/DonationModal";
import { DonationSuccessModal } from "./components/DonationSuccessModal";
import { ConversionCompleteModal } from "./components/ConversionCompleteModal";
import { ServiceWorkerUpdate } from "./components/ServiceWorkerUpdate";

function MainApp() {
  const { isDark } = useTheme();
  const [expertMode, setExpertMode] = useState(false);
  const [showDonationSuccess, setShowDonationSuccess] = useState(false);
  const [isDonationModalOpen, setIsDonationModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [showConversionComplete, setShowConversionComplete] = useState(false);
  const [completedFileCount, setCompletedFileCount] = useState(0);
  const [helpModalConfig, setHelpModalConfig] = useState<{
    initialTab: 'faq' | 'messages' | 'community';
    showMessageForm: boolean;
  }>({
    initialTab: 'faq',
    showMessageForm: false
  });
  const [isConverting, setIsConverting] = useState(false);
  const [isDraggingOverPage, setIsDraggingOverPage] = useState(false);

  // Check URL for donation routes or expert mode
  useEffect(() => {
    const path = window.location.pathname;
    if (path === '/donation/success') {
      setShowDonationSuccess(true);
      window.history.replaceState({}, '', '/');
    } else if (path === '/donation/cancel') {
      setIsDonationModalOpen(true);
      window.history.replaceState({}, '', '/');
    } else {
      // Check for expert mode activation
      const urlChecksum = path.substring(1); // Remove leading slash
      if (urlChecksum && urlChecksum.length === 40) {
        setExpertMode(true);
      }
    }
  }, []);
  const {
    files,
    currentFile,
    currentFileIndex,
    currentFilename,
    removeFile,
    queueFileForDeletion,
    deletionQueue,
    setCurrentFileIndex,
    checksum,
    setChecksum,
    activationBytes,
    setActivationBytes,
    showSizeWarning,
    setShowSizeWarning,
    pendingLargeFiles,
    setPendingLargeFiles,
    processLargeFiles
  } = useFileManager();

  const { handleFiles, setSuppressWarnings } = useFileHandler();

  const handleProceedWithLargeFile = useCallback((suppressWarning?: boolean) => {
    if (pendingLargeFiles.length > 0) {
      // Track that user proceeded with large files
      analytics.trackEvent("LargeFileWarningDismissed", {
        fileCount: pendingLargeFiles.length,
        totalSize: pendingLargeFiles.reduce((sum, file) => sum + file.size, 0),
        suppressWarnings: !!suppressWarning
      });

      // Save warning preference if needed
      if (suppressWarning) {
        setSuppressWarnings(true);
      }

      setShowSizeWarning(false); // Hide warning first
      processLargeFiles(); // Process the pending large files
    }
  }, [pendingLargeFiles, processLargeFiles, setShowSizeWarning, setSuppressWarnings]);

  const { slideDirection, goToNext, goToPrevious, goToIndex } = useCarousel(
    files.length,
    currentFileIndex,
    {
      onSlideChange: (newIndex) => {
        setCurrentFileIndex(newIndex);
      },
    }
  );

  const setDrag = (isDragging: boolean) => {
    // if no files are present, ignore drag events
    if (files.length === 0) return;

    setIsDraggingOverPage(isDragging);
  };

  // Page-level drag and drop
  const { dragHandlers } = useDragAndDrop({
    onFilesDrop: handleFiles,
    fileFilter: (file) => file.name.toLowerCase().endsWith(".aax"),
    onDragEnter: () => setDrag(true),
    onDragLeave: () => setIsDraggingOverPage(false),
    onDrop: () => setIsDraggingOverPage(false),
  });

  // Track theme changes
  useEffect(() => {
    if (isDark) {
      document.documentElement.classList.add("dark");
      document.documentElement.classList.remove("light");
      analytics.trackEvent("ThemeChanged", { theme: "dark" });
    } else {
      document.documentElement.classList.add("light");
      document.documentElement.classList.remove("dark");
      analytics.trackEvent("ThemeChanged", { theme: "light" });
    }
  }, [isDark]);

  // Track expert mode changes
  useEffect(() => {
    analytics.trackEvent("ExpertModeToggled", { enabled: expertMode });
  }, [expertMode]);

  // Auto-advance to next file when conversion completes
  const handleConversionComplete = () => {
    setIsConverting(false);
  };

  const expertModeDiv = expertMode && (
    <ExpertMode
      file={currentFile}
      checksum={checksum}
      setChecksum={setChecksum}
      activationBytes={activationBytes}
      setActivationBytes={setActivationBytes}
    />
  );

  return (
    <main
      {...dragHandlers}
      className={`min-h-screen flex items-center justify-center ${
        isDraggingOverPage ? "drag-highlight" : ""
      }`}
      style={{
        backgroundAttachment: "fixed",
        height: "100%",
        width: "100%",
        position: "relative",
      }}
      role="main"
      aria-label="AAX file converter application"
    >
      {/* Warning Modal */}
      {showSizeWarning && (
        <WarningModal
          isOpen={true}
          onClose={() => {
            setShowSizeWarning(false);
            setPendingLargeFiles([]);
          }}
          onProceed={handleProceedWithLargeFile}
          filename={pendingLargeFiles[0]?.name || ""}
          count={pendingLargeFiles.length}
        />
      )}
      {/* Drag overlay - visible only when dragging over the page */}
      {isDraggingOverPage && (
        <div
          className={`absolute inset-0 z-40 flex items-center justify-center ${
            isDark ? "bg-gray-900/70" : "bg-gray-100/70"
          }`}
          style={{ backdropFilter: "blur(4px)" }}
          role="alert"
          aria-label="Drop zone active"
        >
          <div className="bg-gradient-to-r from-blue-500/30 to-purple-500/30 p-8 rounded-2xl text-center">
            <div className="text-2xl font-bold mb-4">
              {isDark ? (
                <span className="text-white">Drop AAX files here</span>
              ) : (
                <span className="text-gray-800">Drop AAX files here</span>
              )}
            </div>
          </div>
        </div>
      )}

      <Header
        expertMode={expertMode}
        setExpertMode={setExpertMode}
        onMessageClick={(initialTab: "faq" | "messages" | "community" | null, showMessageForm: boolean) => {
          setHelpModalConfig({ initialTab: initialTab || 'faq', showMessageForm });
          setIsMessageModalOpen(true);
        }}
      />
      <section className="w-full max-w-4xl mx-auto p-6 md:p-8 lg:p-12 mt-16" aria-label="File conversion area">
        {files.length === 0 ? (
          <section aria-label="File upload">
            <DropZone />
            {expertModeDiv}
          </section>
        ) : (
          <section className="w-full" aria-label="File conversion controls">
            <section className="relative mb-6" aria-label="File preview and navigation">
              {/* Navigation arrows */}
              <NavigationControls
                onPrevious={(e) => {
                  e.stopPropagation();
                  if (!isConverting) goToPrevious();
                }}
                onNext={(e) => {
                  e.stopPropagation();
                  if (!isConverting) goToNext();
                }}
                disabled={isConverting}
                showControls={files.length > 1}
              />

              {/* Carousel content */}
              <AnimatePresence mode="popLayout">
                <motion.div
                  key={`${currentFilename || currentFileIndex}-${
                    deletionQueue.includes(currentFilename || "") ? "removing" : "normal"
                  }`}
                  initial={{
                    opacity: 0,
                    x: slideDirection === "left" ? 200 : -200,
                    scale: 0.95,
                  }}
                  animate={{
                    opacity: deletionQueue.includes(currentFilename || "") ? 0 : 1,
                    x: 0,
                    y: deletionQueue.includes(currentFilename || "") ? 20 : 0,
                    scale: deletionQueue.includes(currentFilename || "") ? 0.95 : 1,
                  }}
                  exit={{
                    opacity: 0,
                    x: slideDirection === "left" ? -200 : 200,
                    scale: 0.95,
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 300,
                    damping: 30,
                  }}
                  className="flex flex-col items-center gap-4"
                >
                  {currentFile && (
                    <FilePreview
                      file={currentFile}
                      onRemove={() => {
                        if (
                          !isConverting &&
                          currentFilename &&
                          !deletionQueue.includes(currentFilename)
                        ) {
                          const filename = currentFilename; // Capture current filename
                          queueFileForDeletion(filename);
                          // Use RAF to ensure smooth animation
                          requestAnimationFrame(() => {
                            setTimeout(() => {
                              removeFile(filename);
                            }, 300);
                          });
                        }
                      }}
                      isConverting={isConverting}
                    />
                  )}
                </motion.div>
              </AnimatePresence>

              {/* File indicators */}
              {files.length > 1 && (
                <FileIndicators
                  count={files.length}
                  currentIndex={currentFileIndex}
                  disabled={isConverting}
                  onChange={(index) => {
                    if (!isConverting) {
                      goToIndex(index);
                    }
                  }}
                  showCount={true}
                />
              )}
            </section>

            {expertModeDiv}
            <AudioConverter
              file={currentFile}
              onConversionStart={() => setIsConverting(true)}
              onConversionComplete={(fileCount) => {
                console.log("Conversion complete! File count:", fileCount);
                handleConversionComplete();
                
                // Always set the file count to at least 1
                const count = fileCount || 1;
                setCompletedFileCount(count);
                
                // Always show the conversion complete modal when any files are converted
                setShowConversionComplete(true);
                console.log("Modal visibility set to:", true);
              }}
              onCancel={() => {
                setIsConverting(false);
                setCompletedFileCount(0);
              }}
            />
          </section>
        )}
      </section>
      <HelpModal
        isOpen={isMessageModalOpen}
        onClose={() => {
          setIsMessageModalOpen(false);
          setHelpModalConfig({ initialTab: 'faq', showMessageForm: false });
        }}
        initialTab={helpModalConfig.initialTab}
        showMessageForm={helpModalConfig.showMessageForm}
      />

      <DonationModal
        isOpen={isDonationModalOpen}
        onClose={() => setIsDonationModalOpen(false)}
        onMessageClick={() => {
          setIsDonationModalOpen(false);
          setHelpModalConfig({ initialTab: 'messages', showMessageForm: true });
          setIsMessageModalOpen(true);
        }}
      />

      <DonationSuccessModal
        isOpen={showDonationSuccess}
        onClose={() => setShowDonationSuccess(false)}
      />

      <ConversionCompleteModal
        isOpen={showConversionComplete}
        onClose={() => {
          console.log("Closing conversion complete modal");
          setShowConversionComplete(false);
        }}
        onMessageClick={() => {
          setShowConversionComplete(false);
          setHelpModalConfig({ initialTab: 'messages', showMessageForm: true });
          setIsMessageModalOpen(true);
        }}
        fileCount={completedFileCount}
      />
      {/* PWA Update Notification */}
      <ServiceWorkerUpdate />
    </main>
  );
}

function App() {
  return (
    <AppInsightsErrorBoundary>
      <FileProvider>
        <MainApp />
      </FileProvider>
    </AppInsightsErrorBoundary>
  );
}

export default App;
