import React, { Component, ErrorInfo, ReactNode } from 'react';
import { analytics } from '../utils/analytics';

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
    error?: Error;
}

export class AppInsightsErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false
    };

    public static getDerivedStateFromError(error: Error): State {
        return {
            hasError: true,
            error
        };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        analytics.trackException(error, {
            componentStack: errorInfo.componentStack || 'No stack trace available',
        });
    }

    public render(): ReactNode {
        if (this.state.hasError) {
            return (
                <div className="p-4 text-center">
                    <h2 className="text-xl font-bold text-red-600 mb-2">Something went wrong</h2>
                    <p className="text-gray-600">
                        We've been notified and will fix this as soon as possible.
                        Please try refreshing the page.
                    </p>
                </div>
            );
        }

        return this.props.children;
    }
}

export default AppInsightsErrorBoundary;
