import { useCallback, useEffect, useState } from 'react';
import { useFileManager } from '../contexts/FileContext';
import { analytics } from '../utils/analytics';
import { loadSessionPreference, saveSessionPreference } from '../utils/localStorage';

const MAX_FILE_SIZE = 900 * 1024 * 1024; // 600MB

export function useFileHandler() {
  const {
    setShowSizeWarning,
    setPendingLargeFiles,
    processFiles,
    files,
    showSizeWarning,
    pendingLargeFiles
  } = useFileManager();

  // Load suppress preference from session storage
  const [suppressWarnings, setSuppressWarnings] = useState(() => 
    loadSessionPreference('SUPPRESS_SIZE_WARNING', 'false') === 'true'
  );

  // Save preference when it changes
  useEffect(() => {
    saveSessionPreference('SUPPRESS_SIZE_WARNING', suppressWarnings ? 'true' : 'false');
  }, [suppressWarnings]);

  const handleLargeFiles = useCallback((largeFiles: File[]) => {
    if (suppressWarnings) {
      // If warnings are suppressed, process large files immediately
      processFiles(largeFiles);
      return;
    }

    // Otherwise show warning
    setPendingLargeFiles(largeFiles);
    setShowSizeWarning(true);
    analytics.trackEvent("LargeFileWarningShown", {
      fileCount: largeFiles.length,
      totalSize: largeFiles.reduce((sum, file) => sum + file.size, 0)
    });
  }, [suppressWarnings, processFiles, setPendingLargeFiles, setShowSizeWarning]);

  useEffect(() => {
    console.log('useFileHandler state:', {
      fileCount: files.length,
      showSizeWarning,
      pendingLargesCount: pendingLargeFiles.length
    });
  }, [files.length, showSizeWarning, pendingLargeFiles.length]);

  const handleFiles = useCallback((files: File[]) => {
    // Reset warning state at the start
    setShowSizeWarning(false);
    setPendingLargeFiles([]);

    const aaxFiles = files.filter((file) =>
      file.name.toLowerCase().endsWith('.aax')
    );

    if (aaxFiles.length === 0) {
      console.warn("No supported files dropped.");
      analytics.trackEvent("FileDropRejected", {
        reason: "UnsupportedFileType",
      });
      return;
    }

    // Check for large files
    const largeFiles = aaxFiles.filter(file => file.size > MAX_FILE_SIZE);
    const normalFiles = aaxFiles.filter(file => file.size <= MAX_FILE_SIZE);

    console.log('Processing files:', {
      total: aaxFiles.length,
      large: largeFiles.length,
      normal: normalFiles.length
    });

    // Track accepted files
    aaxFiles.forEach((file) => {
      analytics.trackEvent("FileDropAccepted", {
        fileSize: file.size,
        fileName: file.name,
        isLarge: file.size > MAX_FILE_SIZE
      });
    });

    // Always process normal files if they exist
    const hasNormalFiles = normalFiles.length > 0;
    const hasLargeFiles = largeFiles.length > 0;

    // Handle large files first
    if (hasLargeFiles) {
      handleLargeFiles(largeFiles);
    }

    // Process normal files immediately, even if there are large files
    if (hasNormalFiles) {
      processFiles(normalFiles);
    }

    console.log('File handling complete:', {
      hasNormalFiles,
      hasLargeFiles,
      normalCount: normalFiles.length,
      largeCount: largeFiles.length
    });
  }, [processFiles, setShowSizeWarning, setPendingLargeFiles]);

  return {
    handleFiles,
    setSuppressWarnings
  };
}
