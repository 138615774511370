import React from 'react';
import { useTheme } from '../ThemeContext';

interface FileIndicatorsProps {
  count: number;
  currentIndex: number;
  disabled: boolean;
  onChange: (index: number) => void;
  showCount?: boolean;
}

export function FileIndicators({
  count,
  currentIndex,
  disabled,
  onChange,
  showCount = false
}: FileIndicatorsProps) {
  const { isDark } = useTheme();

  return (
    <div className="flex items-center justify-center gap-2 mt-4">
      <div className="flex gap-2">
        {Array.from({ length: count }, (_, index) => (
          <button
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              if (!disabled) {
                onChange(index);
              }
            }}
            disabled={disabled}
            className={`h-2 transition-all duration-200 rounded-full
              ${index === currentIndex 
                ? 'bg-blue-500 w-4' 
                : `${isDark ? 'bg-gray-600' : 'bg-gray-300'} w-2`}
              ${disabled ? 'cursor-not-allowed' : 'hover:opacity-80 cursor-pointer'}`}
            aria-label={`Go to file ${index + 1}`}
          />
        ))}
      </div>
      {showCount && (
        <div className={`text-sm ${isDark ? "text-gray-400" : "text-gray-600"} ml-2`}>
          {currentIndex + 1} of {count}
        </div>
      )}
    </div>
  );
}