import React from 'react';
import { FileAudio, Trash2 } from 'lucide-react';
import { motion } from 'framer-motion';
import { useTheme } from '../ThemeContext';
import { EnrichedFile } from '../models/EnrichedFile';

interface FilePreviewProps {
  file: EnrichedFile;
  onRemove: () => void;
  isConverting: boolean;
}

export function FilePreview({ file, onRemove, isConverting }: FilePreviewProps) {
  const { isDark } = useTheme();

  const handleRemove = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!isConverting) {
      //debugger; // Debugger statement to check if the function is called
      onRemove();
    }
  };

  return (
    <div className="relative w-full text-center">
      {/* Cover image or icon */}
      <div className="flex justify-center mb-4">
        {file.info.Cover_Data ? (
          <motion.img
            src={`data:image/jpeg;base64,${file.info.Cover_Data}`}
            alt="Cover"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{
              type: "tween",
              duration: 0.15,
              ease: "easeInOut"
            }}
            className="rounded-lg w-[min(80%,24rem)] max-w-[48rem]"
            style={{
              boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
            }}
          />
        ) : (
          <FileAudio className="w-32 h-32 text-gray-400" />
        )}
      </div>

      {/* Remove button */}
      <button
        onClick={handleRemove}
        disabled={isConverting}
        className={`absolute right-0 top-0 p-2 rounded-full
          ${isDark ? 'bg-gray-700 hover:bg-red-600' : 'bg-gray-100 hover:bg-red-400'}
          ${isConverting ? 'opacity-50 cursor-not-allowed' : ''}
          transition-colors`}
        aria-label="Remove file"
      >
        <Trash2 className="w-5 h-5" />
      </button>

      {/* File info */}
      <motion.h3
        className={`text-lg font-medium ${isDark ? "text-white" : "text-gray-900"}`}
      >
        {file.friendlyName}
      </motion.h3>
      {file.info.Title && (
        <motion.p
          className={`text-sm ${isDark ? "text-gray-400" : "text-gray-600"}`}
        >
          {file.info.Title}
        </motion.p>
      )}
    </div>
  );
}
