import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App.tsx';
import { ThemeProvider } from './ThemeContext';
import './index.css';
import AppInsightsErrorBoundary from './components/AppInsightsErrorBoundary';
createRoot(document.getElementById('root')!).render(
  // <StrictMode>
    <AppInsightsErrorBoundary>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </AppInsightsErrorBoundary>
  // </StrictMode>
);
