import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { X } from "lucide-react";
import { SupportButtons } from "./SupportButtons";

interface DonationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onMessageClick: () => void;
}

export function DonationModal({ isOpen, onClose, onMessageClick }: DonationModalProps) {
  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 backdrop-blur-sm"
            onClick={onClose}
          />
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 100 }}
            className="relative z-50 w-[95%] max-w-lg max-h-[90vh] overflow-y-auto rounded-lg bg-white shadow-xl dark:bg-gray-800"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="sticky top-0 z-10 bg-white dark:bg-gray-800 px-4 sm:px-6 py-4 border-b border-gray-100 dark:border-gray-700">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-semibold dark:text-white">
                  Support the Project
                </h2>
                <button
                  onClick={onClose}
                  className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
                >
                  <X size={24} />
                </button>
              </div>
            </div>
            
            <div className="p-4 sm:p-6 pt-5">
              <div className="mb-6 text-gray-600 dark:text-gray-300">
                <p className="font-medium mb-2">Thank you for using Audible Tools! ❤️</p>
                <p>Your support helps us:</p>
                <ul className="list-disc ml-5 mt-2 space-y-1">
                  <li>Keep this tool completely free for everyone</li>
                  <li>Develop exciting new features</li>
                  <li>Maintain and improve performance</li>
                </ul>
                <p className="mt-4 text-lg font-bold text-center">Every contribution, no matter the size, truly makes a difference!</p>
              </div>

              <SupportButtons 
                onMessageClick={onMessageClick}
                showGithub={true}
                layout="grid"
              />
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
}
