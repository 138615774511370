import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

console.log('Url:', supabaseUrl);

export const supabase = createClient(supabaseUrl, supabaseKey);

export interface Message {
    id: string;
    created_at: string;
    name: string | null;
    email: string | null;
    message: string;
    parent_id?: string;
    likes?: number;
}

export interface ThreadedMessage extends Message {
    replies?: ThreadedMessage[];
}

interface SendMessageParams extends Omit<Message, 'id' | 'created_at' | 'likes'> {
    captchaToken: string;
}

export async function sendMessage({ name, email, message, captchaToken, parent_id }: SendMessageParams) {
    const isDev = import.meta.env.MODE === 'development';
    console.log('env:', import.meta.env.MODE, isDev);
    if (!isDev) {
        // console.log('CAPTCHA token:', captchaToken);
        const { error } = await supabase.functions.invoke('verify-turnstile-cors', {
            body: { token: captchaToken }
        });
    
        if (error) {
            throw new Error('CAPTCHA verification failed');
        }
    }

    return supabase
        .from('messages')
        .insert([{ name, email, message, parent_id }])
        .select()
        .single();
}

function threadMessages(messages: Message[]): ThreadedMessage[] {
    const messageMap = new Map<string, ThreadedMessage>();
    const rootMessages: ThreadedMessage[] = [];
    
    // First pass: create a map of all messages
    messages.forEach(msg => {
        const threadedMsg: ThreadedMessage = { ...msg, replies: [] };
        messageMap.set(msg.id, threadedMsg);
    });
    
    // Second pass: organize into threads
    messages.forEach(msg => {
        const threadedMsg = messageMap.get(msg.id)!;
        if (msg.parent_id && messageMap.has(msg.parent_id)) {
            const parent = messageMap.get(msg.parent_id)!;
            if (!parent.replies) parent.replies = [];
            parent.replies.push(threadedMsg);
        } else {
            rootMessages.push(threadedMsg);
        }
    });
    
    // Sort messages: root messages by newest first, replies by oldest first
    rootMessages.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
    rootMessages.forEach(msg => {
        if (msg.replies) {
            msg.replies.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
        }
    });
    
    return rootMessages;
}

export async function fetchMessages(): Promise<ThreadedMessage[]> {
    const { data, error } = await supabase
        .from('messages')
        .select('*')
        .order('created_at', { ascending: true })
        .limit(100);

    if (error) {
        console.error('Error fetching messages:', error);
        return [];
    }
    
    return threadMessages(data || []);
}

export async function getMessageLikeCount(messageId: string): Promise<number> {
    const { data, error } = await supabase
        .from('messages_like')
        .select('likecount')
        .eq('messageid', messageId)
        .maybeSingle();

    if (error && error.code !== 'PGRST116') { // PGRST116 is "Row not found" error
        throw error;
    }

    return data?.likecount || 0;
}

export async function toggleMessageLike(messageId: string, isLiked: boolean): Promise<number> {
    const { data, error } = await supabase
        .rpc(isLiked ? 'handle_message_like' : 'handle_message_unlike', {
            message_id: messageId
        })
        .throwOnError();

    if (error) throw error;
    if (data === null || data === undefined || data.length === 0) throw new Error('No message returned');

    // Return the likes count from the response message
    return data[0].likes;
}
