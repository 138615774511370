import React from "react";
import { CreditCard, MessageCircle, Github } from "lucide-react";
import paypalLogo from "../assets/paypal_PNG7.webp";
import { DiscordIcon } from "../assets/icons";

interface SupportButtonsProps {
  onMessageClick?: () => void;
  className?: string;
  layout?: "grid" | "compact";
  showGithub?: boolean;
}

const baseButtonStyle = `
  flex flex-col items-center gap-3 p-4 border border-gray-200 rounded-lg 
  hover:border-blue-500 dark:border-gray-700 dark:hover:border-blue-500 
  transition-colors
`;

export function SupportButtons({
  onMessageClick,
  className = "",
  layout = "grid",
  showGithub = true,
}: SupportButtonsProps) {
  const wrapperClass = layout === "grid"
    ? "grid grid-cols-1 md:grid-cols-2 gap-4"
    : "flex flex-wrap gap-4 justify-center";

  const buttonClass = layout === "grid"
    ? baseButtonStyle
    : `${baseButtonStyle} flex-1 min-w-[180px] max-w-[220px]`;

  return (
    <div className={`${wrapperClass} ${className}`}>
      {/* PayPal */}
      <button
        onClick={() => {
          const baseUrl = window.location.origin;
          const successUrl = `${baseUrl}/donation/success`;
          const cancelUrl = `${baseUrl}/donation/cancel`;
          window.open(`https://www.paypal.com/paypalme/jdawg1337?return=${encodeURIComponent(successUrl)}&cancel=${encodeURIComponent(cancelUrl)}`, "_blank");
        }}
        className={buttonClass}
      >
        <img src={paypalLogo} alt="PayPal" className="w-8 h-8 object-contain" />
        <div className="text-center">
          <div className="font-medium dark:text-white">Donate via PayPal</div>
          <div className="text-sm text-gray-500 dark:text-gray-400">
            Quick and secure
          </div>
        </div>
      </button>

      {/* Stripe */}
      <button
        onClick={() => {
          const link = "https://donate.stripe.com/eVag2kduCaD226keUU";
          window.open(link, "_blank");
        }}
        className={buttonClass}
      >
        <CreditCard className="w-8 h-8 text-[#6772E5]" />
        <div className="text-center">
          <div className="font-medium dark:text-white">Donate via Card or Bank</div>
          <div className="text-sm text-gray-500 dark:text-gray-400">
            Via Stripe
          </div>
        </div>
      </button>

      {showGithub && (
        <button
          onClick={() =>
            window.open(
              "https://github.com/audible-tools/audible-tools.github.io/issues",
              "_blank"
            )
          }
          className={buttonClass}
        >
          <Github className="w-8 h-8" />
          <div className="text-center">
            <div className="font-medium dark:text-white">
              Create Feature Request
            </div>
            <div className="text-sm text-gray-500 dark:text-gray-400">
              Suggest improvements
            </div>
          </div>
        </button>
      )}

      {onMessageClick && (
        <button onClick={onMessageClick} className={buttonClass}>
          <MessageCircle className="w-8 h-8 text-blue-500" />
          <div className="text-center">
            <div className="font-medium dark:text-white">Send a Message</div>
            <div className="text-sm text-gray-500 dark:text-gray-400">
              Get in touch
            </div>
          </div>
        </button>
      )}

      {/* Discord */}
      <button
        onClick={() =>
          window.open("https://discord.com/invite/P9evWVw8DW", "_blank")
        }
        className={`${buttonClass} ${layout === "grid" ? "md:col-span-2" : ""}`}
      >
        <div className="text-[#5865F2]">{DiscordIcon}</div>
        <div className="text-center">
          <div className="font-medium dark:text-white">
            Join Discord Community
          </div>
          <div className="text-sm text-gray-500 dark:text-gray-400">
            Connect with other users
          </div>
        </div>
      </button>
    </div>
  );
}
